import React from "react";

import { Link } from "react-router-dom";

import Masonry from "react-masonry-css";

import "./style.css";

import { url } from "../../../utils/vars";

export default function PostList({ dados }) {
    function mapFotos(data) {
        return data.map((d) => {
            console.log(d);

            return (
                <div key={d.id} className="photo">
                    <Link to={`/post/${d.data.pub}`}>
                        <img src={`${url}${d.pictures[0]}`} alt="Post" />
                    </Link>
                </div>
            );
        });
    }

    return (
        <div className="mob-post-list-container">
            <Masonry
                breakpointCols={3}
                className="mob-post-list-grid"
                columnClassName="mob-post-list-grid_column"
            >
                {mapFotos(dados)}
            </Masonry>
        </div>
    );
}
