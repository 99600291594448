import React from "react";

import "./style.css";

export default function ModalTerms({ onClose = () => {} }) {
    return (
        <>
            <div className="modal-termos">
                <div className="close" onClick={() => onClose()}>
                    &#967;
                </div>
                <h1>Termos de Uso</h1>

                <p>
                    <br />
                    <b>Informações Básicas da Conta</b>
                    <br></br>
                    Você não precisa criar uma conta para usar alguns dos
                    recursos dos nossos serviços, como buscar e visualizar
                    perfis públicos de usuários do My Best Trip bem como suas
                    publicações diversas. Se você optar, no entanto, por criar
                    uma conta, você precisa nos fornecer alguns dados pessoais
                    para que possamos fornecer nossos serviços a você. Isso
                    inclui um nome de exibição (por exemplo, Explorador do
                    Mundo) e um nome de usuário (por exemplo,
                    @exploradordomundo), uma senha e um endereço de e-mail ou
                    número de telefone. Seu nome de exibição e nome de usuário
                    são sempre públicos; você também pode usar seu nome real ou
                    um pseudônimo. Você pode criar e gerenciar múltiplas contas
                    no My Best Trip.<br></br>
                    <br></br>
                    <b>Informações Públicas</b>
                    <br></br>À exceção da sua senha, endereço de email e
                    telefone, TODAS as informações que você adicionar ao My Best
                    Trip são de domínio público e estarão disponíveis a qualquer
                    pessoa que, cadastrada ou não no My Best Trip, entre no site
                    e nele navegue. Também são de domínio público TODAS as
                    atitudes iterativas de outros participantes sobre seu perfil
                    e suas publicações no My Best Trip, tais como atribuição de
                    notas e comentários.<br></br>
                    <br></br>
                    <b>Informações de Contato e Catálogos de Endereços</b>
                    <br></br>
                    Usamos suas informações de contato, como seu endereço de
                    e-mail ou número de telefone, para autenticar sua conta e
                    mantê-la, para manter os nossos serviços em segurança e para
                    ajudar a prevenir spam, fraude e abuso. Também usamos
                    informações de contato para personalizar nossos serviços,
                    habilitar certos recursos da conta, e para enviar
                    informações sobre nossos serviços, incluindo anúncios. Se
                    você fornecer ao My Best Trip seu número de telefone, você
                    concorda em receber mensagens de texto nossas nesse número,
                    conforme permitido pelas leis do seu país. My Best Trip
                    também usa suas informações de contato para direcionar
                    marketing a você conforme as leis do seu país permitirem, e
                    para ajudar outras pessoas a encontrar sua conta.<br></br>
                    <br></br>
                    <b>
                        Enviando ou recebendo comentários sobre uma publicação
                    </b>
                    <br></br>
                    Quando você se comunicar com outras pessoas ao comentar e
                    receber comentários sobre publicações, nós armazenaremos e
                    processaremos, eventualmente, suas comunicações e
                    informações relacionadas a elas. Isso inclui a verificação
                    eventual de links para conteúdo nocivo, a detecção de spam,
                    abuso, incitação a violência e imagens proibidas, sempre de
                    forma eventual.<br></br>
                    <br></br>
                    <b>Links</b>
                    <br></br>
                    Para operar nossos serviços, acompanhamos como você interage
                    com os links em nossos serviços. Se você clicar em um link
                    ou anúncio externo em nossos serviços, esse anunciante ou
                    operador de site identificará que você chegou a ele através
                    do My Best Trip. Eles também podem coletar de você outros
                    dados pessoais, como identificadores de cookies ou seu
                    endereço IP.<br></br>
                    <br></br>
                    <b>Cookies</b>
                    <br></br>
                    Um cookie é um pequeno arquivo de dados que é armazenado em
                    seu computador ou dispositivo móvel. Como muitos sites,
                    usamos cookies e tecnologias semelhantes para coletar dados
                    adicionais de uso do site e para operar nossos serviços. Os
                    cookies não são requeridos para muitas partes de nossos
                    serviços, como buscar e consultar perfis públicos. Embora a
                    maioria dos navegadores da Web aceite cookies
                    automaticamente, as configurações de muitos navegadores
                    podem ser modificadas para recusar cookies ou alertá-lo
                    quando um site estiver tentando inserir um cookie em seu
                    computador . No entanto, é possível que alguns de nossos
                    serviços não funcionem adequadamente se você desativar os
                    cookies. Quando seu navegador ou dispositivo permitir, nós
                    usaremos cookies de sessão e cookies persistentes para
                    entender melhor como você interage com nossos serviços,
                    monitorar padrões de uso agregados, personalizar e operar
                    nossos serviços, fornecer segurança de conta, personalizar o
                    conteúdo que mostramos a você, inclusive anúncios, e lembrar
                    suas preferências de idioma.<br></br>
                    <br></br>
                    <b>Dados de Registro</b>
                    <br></br>
                    Nós recebemos informações quando você visualiza conteúdo em
                    ou interage com nossos serviços, o que nós chamamos de
                    “Dados de Registro”, mesmo se não tiver criado uma conta.
                    Por exemplo, quando você visita os nossos sites, acessa os
                    nossos serviços, interage com as nossas notificações por
                    e-mail, utiliza a sua conta para se autenticar em um site,
                    aplicativo ou serviço de terceiro, ou quando visita um site,
                    aplicativo ou serviço de terceiro que inclua conteúdo do My
                    Bst Trip, podemos receber informações sobre você. Esses
                    Dados de Registro podem incluir informações como o seu
                    endereço IP, tipo de navegador, sistema operacional, página
                    da Web de origem, páginas da Web visitadas, localização, sua
                    operadora de telefonia celular, informações de dispositivo
                    (inclusive IDs de dispositivo e aplicativos), termos de
                    pesquisa (incluindo aqueles que não foram enviados como
                    consultas) e informações de cookies. Também recebemos Dados
                    de Registro quando você clica, visualiza ou interage com
                    links em nossos serviços.<br></br>
                    <br></br>
                    <b>Anúncios</b>
                    <br></br>
                    Nossas políticas de anúncios proíbem que anunciantes
                    segmentem anúncios com base em categorias que considerarmos
                    sensíveis ou proibidas pela legislação, como raça, religião,
                    política, vida sexual ou saúde. Se você é um anunciante em
                    potencial, entre em contato conosco diretamente.
                </p>
            </div>
            <div onClick={() => onClose()} className="black-ovl"></div>
        </>
    );
}
