import put from "./put.fetch";

import CryptoJS from "crypto-js";

export default async function newPass(password, username, uuid) {
    //RECEIVE EMAIL

    const password_hash = CryptoJS.SHA3(password, {
        outputLength: 512,
    }).toString();

    //REQUEST TO API ON /FORGOT-PASSWORD

    const { status } = await put(
        "/users/reset-password",
        {},
        {
            username,
            uuid,
            newPassword: password_hash,
        }
    );

    //HANDLE STATUS CODE

    console.log(status);

    switch (status) {
        case 200:
            //SUCCESS
            return {
                status: 200,
                message: "OK",
            };

        default:
            //ERROR
            return {
                status: 999,
                message: "Ocorreu um erro, tente novamente mais tarde",
            };
    }

    /*
        RETURN STRUC {
            STATUS: NUMBER (HTTPS STATUS CODE),
            MESSAGE: STRING
        }
    */
}
