import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

import getCookies from "../../../hooks/cookie";
import get from "../../../hooks/get.fetch";

import Header from "../../components/header";
import Hero from "../../components/hero";
import Loading from "../../components/loading_ovl";
import ListaPosts from "../../components/lista_posts2";

export default function Search() {
    const { query } = useParams();
    const { id, hash } = getCookies();

    const { data, error } = useSWR(["/search", query], (url, query) =>
        get(url, { id, hash }, { keyword: query })
    );

    if (error) {
        return (
            <>
                <Header></Header>
                <Hero type="search" q={query}></Hero>
                <div className="desktop-search-container">
                    <h1 className="notice">
                        Ocorreu um erro. Tente novamente mais tarde.
                    </h1>
                </div>
            </>
        );
    }

    if (!data) {
        return (
            <>
                <Loading></Loading>
                <Header></Header>
                <Hero type="search" q={query}></Hero>
            </>
        );
    }

    if (data.length === 0) {
        return (
            <>
                <Header></Header>
                <Hero type="search" q={query}></Hero>
                <div className="desktop-search-container">
                    <h1 className="notice">Nenhum post encontrado</h1>
                </div>
            </>
        );
    }

    return (
        <>
            <Header></Header>
            <Hero type="search" q={query}></Hero>
            <div className="desktop-search-container">
                <ListaPosts dados={data}></ListaPosts>
            </div>
        </>
    );
}
