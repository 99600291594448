import React from "react";

import ErrorOvl from "../../components/error_ovl";

export default function FeedError() {
    return (
        <>
            <ErrorOvl error="deslogado"></ErrorOvl>
            <div className="bg_full"></div>
        </>
    );
}
