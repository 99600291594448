import Cookies from "universal-cookie";

import post from "./post.fetch";

export default async function follow(id) {
    const cookies = new Cookies();
    const idLogado = cookies.get("id");
    const hash = cookies.get("hash");

    //RECEIVE ID

    //REQUEST TO API ON /FOLLOW

    const { status } = post(
        "/follow",
        { id: idLogado, hash },
        { userToFollow: id }
    );

    //HANDLE STATUS CODE

    switch (status) {
        case 200:
            //SUCCESS
            return {
                status: 200,
                message: "OK",
            };

        default:
            //ERROR
            return {
                status: 999,
                message: "Ocorreu um erro. Tente novamente mais tarde.",
            };
    }

    /*
        RETURN STRUC {
            STATUS: NUMBER (HTTPS STATUS CODE),
            MESSAGE: STRING
        }
    */
}
