import React from 'react';
import { BsFillGridFill } from 'react-icons/bs';

import './style.css';

export default function Entry() {
    return (
        <div className="entry-photos-container">
            <BsFillGridFill className="icon active"></BsFillGridFill>
        </div>
    );
}
