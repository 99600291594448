import Cookies from "universal-cookie";

export default function getCookies() {
    const cookies = new Cookies();

    const id = cookies.get("id");
    const hash = cookies.get("hash");

    return { id, hash };
}
