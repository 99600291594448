import React from "react";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";

import "./style.css";

//IMPORT GET COOKIES FUNCTION
import getCookies from "../../../hooks/cookie";

//IMPORT API URL

//IMPORT FEED SWR HOOK
import get from "../../../hooks/get.fetch";

import LoadingOvl from "../../components/loading_ovl";
import ErrorOvl from "../../components/error_ovl";
import ListaPosts from "../../components/lista_post";

export default function Post() {
    const { id, hash } = getCookies();
    const history = useHistory();

    const { id: id_post } = useParams();

    //SE O USUÁRIO NÃO ESTIVER LOGADO
    if (!id || !hash) {
        history.push("/feed/error");
    }

    const { data, error } = useSWR(["/post", id_post], (url, id_post) =>
        get(url, { id, hash }, { pubID: id_post })
    );

    if (error) {
        return (
            <>
                <ErrorOvl error={999}></ErrorOvl>
                <div className="bg_full"></div>
            </>
        );
    }

    if (!data) {
        return (
            <>
                <LoadingOvl></LoadingOvl>
                <div className="bg_full"></div>
            </>
        );
    }

    if (data.length === 0) {
        return (
            <>
                <ErrorOvl error="feed_sem"></ErrorOvl>
                <div className="bg_full"></div>
            </>
        );
    }

    //SE O USUÁRIO ESTIVER LOGADO
    return (
        <>
            <div>
                <ListaPosts dados={[data]} type="normal"></ListaPosts>
            </div>
        </>
    );
}
