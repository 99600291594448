import React from "react";
import { useStoreActions } from "easy-peasy";
import { useHistory } from "react-router-dom";

import { BsXCircleFill } from "react-icons/bs";

import "./style.css";

import Lista from "../../components/newFile_list";

import Form from "../../components/newFile_form";

export default function NewPost() {
    const history = useHistory();

    const apagarImagens = useStoreActions(
        (action) => action.newFile.actionApagar
    );

    async function handleExit() {
        await apagarImagens();
        history.goBack();
    }

    return (
        <>
            <div className="desktop-new-post-container">
                <Lista></Lista>

                <Form></Form>

                <div onClick={() => handleExit()} className="x">
                    <BsXCircleFill></BsXCircleFill>
                </div>
            </div>

            <div className="bg_full"></div>
        </>
    );
}
