import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import TextareaAutosize from "react-textarea-autosize";
import "react-toastify/dist/ReactToastify.css";

import signIn from "../../../hooks/signin";

import "./style.css";

import Logo from "../../../assets/logo_novo.png";
import SemFoto from "../../../assets/semfoto.jpg";
import LoadingOvl from "../../components/loading_ovl";
import ModalTerms from "../../components/modalTerms";

export default function Signin() {
    const history = useHistory();
    const input = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    //SELECT AND PREVIEW IMAGE

    const [selectedPic, setSelectedPic] = useState(undefined);
    const [previewImage, setPreviewImage] = useState(undefined);

    function selectImage(img) {
        setSelectedPic(img[0]);
        setPreviewImage(URL.createObjectURL(img[0]));
    }

    //FORM VALIDATION

    // INIT USE FORM HOOK
    const { register, errors, handleSubmit, setError } = useForm();

    //HANDLE SUBMIT
    const useSubmit = async (data) => {
        setIsLoading(true);
        if (data.senha !== data.conf) {
            setError("igual", { type: "manual" });
            setIsLoading(false);
        } else {
            const res = await signIn(data, selectedPic);

            if (res.status === 200) {
                history.push("/");
            } else {
                setIsLoading(false);
                toast.error(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: res.status,
                });
            }
        }
    };

    return (
        <>
            {isOpen && (
                <ModalTerms
                    onClose={() => {
                        setIsOpen(false);
                    }}
                ></ModalTerms>
            )}
            {isLoading && <LoadingOvl></LoadingOvl>}
            <div className="desktop-landingPage-container">
                <div className="texts">
                    <img src={Logo} alt="Logo" />
                    <h1>My Best Trip</h1>
                    <h2>aqui você compartilha suas melhores viagens</h2>
                </div>

                <form onSubmit={handleSubmit(useSubmit)}>
                    <p>Email</p>
                    <input
                        type="text"
                        name="email"
                        placeholder="johndoe@gmail.com"
                        className={errors.email && "error"}
                        ref={register({ required: true })}
                    />
                    <p>Celular</p>
                    <input
                        type="text"
                        name="celular"
                        placeholder="11999999999"
                        className={errors.celular && "error"}
                        ref={register({ required: true })}
                    />
                    <p>Senha</p>
                    <input
                        type="password"
                        name="senha"
                        placeholder="**********"
                        className={errors.senha && "error"}
                        ref={register({ required: true })}
                    />
                    <p>Confirme a Senha</p>
                    <input
                        type="password"
                        name="conf"
                        placeholder="**********"
                        className={errors.igual || (errors.conf && "error")}
                        ref={register({ required: true })}
                    />

                    <p>Nome Completo</p>
                    <input
                        type="text"
                        name="nome"
                        placeholder="Ex: John Doe"
                        className={errors.nome && "error"}
                        ref={register({ required: true })}
                    />
                    <p>Usuário</p>
                    <input
                        type="text"
                        name="user"
                        placeholder="Ex: johndoe"
                        className={errors.user && "error"}
                        ref={register({ required: true })}
                    />
                    <p>Descrição</p>
                    <TextareaAutosize
                        maxLength={150}
                        type="text"
                        name="desc"
                        placeholder="Ex: Sou um amante de montanhismo, ciclismo e aventuras 🚵🏼‍♂️"
                        className={errors.desc && "error"}
                        ref={register({ required: true })}
                    />
                    <p>Foto</p>
                    <input
                        ref={input}
                        style={{ display: "none" }}
                        type="file"
                        onChange={(a) => {
                            selectImage(a.target.files);
                        }}
                    />
                    <img
                        onClick={() => {
                            input.current.click();
                        }}
                        src={previewImage ? previewImage : SemFoto}
                        alt=""
                    />
                    <div className="checkbox">
                        <input
                            ref={register({ required: true })}
                            type="checkbox"
                            name="checkbox"
                        />
                        <label
                            className={errors.checkbox && "label-error"}
                            htmlFor="checkbox"
                        >
                            Aceito os{" "}
                            <b
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                Termos de Uso
                            </b>
                        </label>
                    </div>

                    <button type="submit">Enviar</button>

                    {errors.email ||
                    errors.user ||
                    errors.desc ||
                    errors.celular ||
                    errors.nome ||
                    errors.senha ? (
                        <p className="error">
                            Preencha todos os campos corretamente.<br></br>
                        </p>
                    ) : null}

                    {errors.igual && (
                        <p className="error">As senhas não coincidem.</p>
                    )}
                    <Link to="/">Já tem uma conta?</Link>
                </form>
            </div>
        </>
    );
}
