import post from "./post.fetch";

export default async function lostPass(email) {
    //RECEIVE EMAIL

    //REQUEST TO API ON /FORGOT-PASSWORD

    const { status } = post("/users/forgot-password", {}, { email });

    //HANDLE STATUS CODE

    console.log(status);

    switch (status) {
        case 200:
            //SUCCESS
            return {
                status: 200,
                message: "OK",
            };

        default:
            //ERROR
            return {
                status: 200,
                message: "OK",
            };
    }

    /*
        RETURN STRUC {
            STATUS: NUMBER (HTTPS STATUS CODE),
            MESSAGE: STRING
        }
    */
}
