import React from "react";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";

import "./style.css";

//IMPORT GET COOKIES FUNCTION
import getCookies from "../../../hooks/cookie";

//IMPORT API URL

//IMPORT FEED SWR HOOK
import get from "../../../hooks/get.fetch";

import LoadingOvl from "../../components/loading_ovl";
import ErrorOvl from "../../components/error_ovl";
import ListaPosts from "../../components/posts_search";
import MenuInf from "../../components/menu_inferior";
import Header from "../../components/header";

export default function Top() {
    const { id, hash } = getCookies();
    const history = useHistory();

    //SE O USUÁRIO NÃO ESTIVER LOGADO
    if (!id || !hash) {
        history.push("/feed/error");
    }

    const { query } = useParams();

    const { data, error } = useSWR(["/search", query], (url, query) =>
        get(url, { id, hash }, { keyword: query })
    );

    if (error) {
        return (
            <>
                <ErrorOvl error={999}></ErrorOvl>
                <div className="bg_full"></div>
            </>
        );
    }

    if (!data) {
        return (
            <>
                <LoadingOvl></LoadingOvl>
                <div className="bg_full"></div>
            </>
        );
    }

    if (data.length === 0) {
        return (
            <>
                <ErrorOvl error="feed_sem"></ErrorOvl>
                <div className="bg_full"></div>
            </>
        );
    }

    //SE O USUÁRIO ESTIVER LOGADO
    return (
        <>
            <Header tipo="pesquisar"></Header>
            <div style={{ paddingTop: "60px" }}>
                <ListaPosts dados={data}></ListaPosts>
            </div>
            <MenuInf bg=""></MenuInf>
        </>
    );
}
