import React, { useState } from "react";

import { useForm } from "react-hook-form";

import { useHistory, Link } from "react-router-dom";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";



import lostPass from "../../../hooks/lostPass.swr";



import "./style.css";



import Logo from "../../../assets/logo_novo.png";

import LoadingOvl from "../../components/loading_ovl";



export default function LostPassword() {

    const history = useHistory();



    const [isLoading, setIsLoading] = useState(false);



    //FORM VALIDATION



    // INIT USE FORM HOOK

    const { register, errors, handleSubmit } = useForm();



    //HANDLE SUBMIT

    const useSubmit = async (data) => {

        setIsLoading(true);

        const res = await lostPass(data.email);



        if (res.status === 200) {

            toast.success("Foi enviado um link para seu email. Por favor aguarde alguns minutos.", {

                position: "top-right",

                autoClose: 15000,

                hideProgressBar: false,

                closeOnClick: true,

                pauseOnHover: true,

                draggable: true,

                progress: undefined,

                toastId: res.status,

            });

            history.push("/");

        } else {

            setIsLoading(false);

            toast.error(res.message, {

                position: "top-right",

                autoClose: 5000,

                hideProgressBar: false,

                closeOnClick: true,

                pauseOnHover: true,

                draggable: true,

                progress: undefined,

                toastId: res.status,

            });

        }

    };



    return (

        <>

            {isLoading && <LoadingOvl></LoadingOvl>}

            <div className="mobile-landingPage-container cad">

                <div className="texts">

                    <img src={Logo} alt="Logo" />

                    <h1>My Best Trip</h1>

                    <h2>aqui você compartilha suas melhores viagens</h2>

                </div>



                <form onSubmit={handleSubmit(useSubmit)}>

                    <p>Email</p>

                    <input

                        type="text"

                        name="email"

                        placeholder="johndoe@gmail.com"

                        className={errors.email && "error"}

                        ref={register({ required: true })}

                    />



                    <button type="submit">Enviar</button>



                    {errors.email ? (

                        <p className="error">

                            Preencha todos os campos corretamente.<br></br>

                        </p>

                    ) : null}

                    <Link to="/">Quer voltar para o login?</Link>

                </form>

            </div>

        </>

    );

}

