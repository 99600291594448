import React from "react";
import { SocialIcon } from "react-social-icons";
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";

export default function Share() {
    return (
        <div className="social-icons-share">
            <FacebookShareButton
                url="https://my-best-trip.com"
                quote="Acesse: https://my-best-trip.com"
                hashtag="mybesttrip"
            >
                <SocialIcon network="facebook"></SocialIcon>
            </FacebookShareButton>

            <TelegramShareButton url="https://my-best-trip.com" title="Acesse: https://my-best-trip.com">
                <SocialIcon network="telegram"></SocialIcon>
            </TelegramShareButton>

            <TwitterShareButton url="https://my-best-trip.com" title="Acesse: https://my-best-trip.com">
                <SocialIcon network="twitter"></SocialIcon>
            </TwitterShareButton>

            <WhatsappShareButton url="https://my-best-trip.com" title="Acesse: ">
                <SocialIcon network="whatsapp"></SocialIcon>
            </WhatsappShareButton>
        </div>
    );
}
