import api from "../utils/api";

export default async function post(url, header, query) {
    let res;
    await api
        .post(url, query, {
            headers: header,
        })
        .then((response) => {
            res = { res: response.data, status: response.status };
        })
        .catch((err) => {
            res = { res: err.response.data, status: err.response.status };
        });

    return { data: res.res, status: res.status };
}
