import React from "react";
import { BsXCircle } from "react-icons/bs";
import { useStoreActions } from "easy-peasy";

import "./style.css";

export default function Photo({ imagem, chave }) {
    const apagarImagem = useStoreActions(
        (action) => action.newFile.actionApagarUma
    );

    return (
        <>
            <div className="desktop-with-photo-container">
                <BsXCircle
                    onClick={() => apagarImagem(chave)}
                    className="delete"
                ></BsXCircle>
                <img src={imagem} alt="phot" className="image" />
            </div>
        </>
    );
}
