import Cookies from "universal-cookie";
import put from "./put.fetch";
import post from "./postPhoto.fetch";

import CryptoJS from "crypto-js";

export default async function editProfile(foto, data, change) {
    const cookies = new Cookies();
    const id = cookies.get("id");
    const hash = cookies.get("hash");

    const password_hash = CryptoJS.SHA3(data.senha, {
        outputLength: 512,
    }).toString();

    //RECEIVE PHOTO AND DESCRIPTION

    //SEND A PUT REQUEST TO /USERS

    const { status } = await put(
        "/users",
        { id, hash },
        {
            description: data.desc,
            name: data.nome,
            username: data.user,
            password: password_hash || "",
            cellphone: data.celular || "",
            email: data.email,
        }
    );

    //HANDLE STATUS CODE

    switch (status) {
        case 200:
            //SUCCESS CASE
            if (change === false) {
                return {
                    status: 200,
                    message: "Editado com sucesso.",
                };
            }
            break;

        case 409:
            //SUCCESS CASE
            return {
                status: 409,
                message: "Já existe uma conta com esse usuário/email/telefone.",
            };

        default:
            //NON CONTROLLED ERROR CASE
            return {
                status: 999,
                message: "Ocorreu um erro. Tente novamente mais tarde.",
            };
    }

    //SEND REQUEST TO /USERS/UPLOADS FOR PHOTO

    let formData = new FormData();
    formData.append("img", foto);

    //REQUEST TO API FOR USER PHOTO
    const { status: statusPhoto } = await post(
        "/users/uploads",
        {
            "Content-Type": "multipart/form-data",
            id,
            hash,
        },
        formData
    );

    //HANDLE STATUS CODE

    switch (statusPhoto) {
        case 200:
            //SUCESS
            return {
                status: 200,
                message: "OK 👍",
            };

        default:
            //ERROR
            return {
                status: 999,
                message: "Ocorreu um erro. Tente novamente mais tarde.",
            };
    }

    /*RETURN STRUC {
        STATUS: NUMBER (HTTPS STATUS CODE),
        MESSAGE: STRING
    }*/
}
