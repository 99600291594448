import React from "react";

import Cookies from "universal-cookie";

import useSWR from "swr";

import {

    BsSearch,

    BsPlusSquareFill,

    BsHouseFill,

    BsTrophy,

    BsBellFill,

} from "react-icons/bs";

import Dropdown from "rc-dropdown";

import Menu, { Divider } from "rc-menu";

import { useHistory, Link } from "react-router-dom";

import { useForm } from "react-hook-form";



import "rc-dropdown/assets/index.css";



import "./style.css";



import Logo from "../../../assets/logo.png";

import Notify from "../notify";



import get from "../../../hooks/get.fetch";

import { url } from "../../../utils/vars";



export default function Header() {

    const history = useHistory();

    const cookies = new Cookies();

    const id = cookies.get("id");

    const hash = cookies.get("hash");



    const { register, errors, handleSubmit } = useForm();



    const useSubmit = async (data) => {

        history.push(`/search/${data.query}`);

    };



    const { data, error } = useSWR(["/users", id], (url, id) =>

        get(url, { id, hash }, { id }, {refreshInterval: 0})

    );



    if (error) {

        return <h1>{error.response.status}</h1>;

    }



    if (!data) {

        return <div className="menu-desktop-sup-container"></div>;

    }



    function logOut() {

        cookies.remove("id", { path: "/" });

        cookies.remove("hash", { path: "/" });



        history.push("/");

    }



    const menu = (

        <Menu>

            <button

                onClick={() => {

                    history.push(`/profile/${id}`);

                }}

            >

                Meu perfil

            </button>

            <Divider />

            <button

                onClick={() => {

                    logOut();

                }}

            >

                Sair

            </button>

        </Menu>

    );



    const modNot = <Notify></Notify>;



    return (

        <div className="menu-desktop-sup-container">

            <div className="left">

                <Link to="/feed">

                    <img src={Logo} alt="logo" />

                </Link>

            </div>

            <div className="right">

                <div className="pesquisar">

                    <form onSubmit={handleSubmit(useSubmit)}>

                        <input

                            className={errors.query ? "error" : ""}

                            type="text"

                            name="query"

                            ref={register({ required: true })}

                            placeholder="Digite sua pesquisa..."

                        />

                        <button type="submit">

                            <BsSearch></BsSearch>

                        </button>

                    </form>

                </div>

                <div className="menu">

                    <BsPlusSquareFill

                        onClick={() => {

                            history.push("/new");

                        }}

                        className="plus"

                    ></BsPlusSquareFill>

                    <BsHouseFill

                        onClick={() => {

                            history.push("/feed");

                        }}

                    ></BsHouseFill>

                    <BsTrophy

                        onClick={() => {

                            history.push("/top");

                        }}

                    ></BsTrophy>

                    <Dropdown

                        trigger={["click"]}

                        overlay={modNot}

                        animation="slide-up"

                    >

                        <BsBellFill></BsBellFill>

                    </Dropdown>



                    {id ? (

                        <Dropdown

                            trigger={["click"]}

                            overlay={menu}

                            animation="slide-up"

                        >

                            <div className="perfil">

                                <img

                                    alt="profile"

                                    src={`${url}${data.picture}`}

                                />

                            </div>

                        </Dropdown>

                    ) : (

                        <div className="naologado">

                            <button className="inv">Entrar</button>

                            <button className="sol">Cadastrar</button>

                        </div>

                    )}

                </div>

            </div>

        </div>

    );

}

