import React, { useRef } from "react";
import { useStoreActions } from "easy-peasy";

import "./style.css";

export default function NoPhoto() {
    const inputFile = useRef(null);

    const selectImage = useStoreActions(
        (action) => action.newFile.actionLoadFeed
    );

    return (
        <div
            onClick={() => inputFile.current.click()}
            className="desktop-no-photo-container"
        >
            <button>
                Clique para
                <br />
                escolher uma foto
            </button>
            <input
                onChange={(e) => {
                    selectImage(e);
                }}
                type="file"
                multiple
                accept="image/*"
                ref={inputFile}
                style={{ display: "none" }}
            />
        </div>
    );
}
