import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./style.css";

import Logo from "../../../assets/logo_novo.png";
import LoadingOvl from "../../components/loading_ovl";
import newPass from "../../../hooks/newPass.swr";

export default function Signin() {
    const history = useHistory();
    const params = useLocation();

    const urlParams = new URLSearchParams(params.search);
    const uuid = urlParams.get("uuid");
    const username = urlParams.get("username");

    const [isLoading, setIsLoading] = useState(false);

    //FORM VALIDATION

    // INIT USE FORM HOOK
    const { register, errors, handleSubmit, setError } = useForm();

    //HANDLE SUBMIT
    const useSubmit = async (data) => {
        setIsLoading(true);
        if (data.senha !== data.conf) {
            setError("igual", { type: "manual" });
            setIsLoading(false);
        } else {
            const res = await newPass(data.senha, username, uuid);

            console.log(res);

            if (res.status === 200) {
                toast.success("Senha atualizada com sucesso.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: res.status,
                });
                history.push("/");
                setIsLoading(false);
            } else {
                setIsLoading(false);
                toast.error(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: res.status,
                });
            }
        }
    };

    return (
        <>
            {isLoading && <LoadingOvl></LoadingOvl>}
            <div className="desktop-landingPage-container">
                <div className="texts">
                    <img src={Logo} alt="Logo" />
                    <h1>My Best Trip</h1>
                    <h2>aqui você compartilha suas melhores viagens</h2>
                </div>

                <form onSubmit={handleSubmit(useSubmit)}>
                    <p>Senha</p>
                    <input
                        type="password"
                        name="senha"
                        placeholder="**********"
                        className={errors.senha && "error"}
                        ref={register({ required: true })}
                    />
                    <p>Confirme a Senha</p>
                    <input
                        type="password"
                        name="conf"
                        placeholder="**********"
                        className={errors.igual || (errors.conf && "error")}
                        ref={register({ required: true })}
                    />

                    <button type="submit">Enviar</button>

                    {errors.senha ? (
                        <p className="error">
                            Preencha todos os campos corretamente.<br></br>
                        </p>
                    ) : null}

                    {errors.igual && (
                        <p className="error">As senhas não coincidem.</p>
                    )}
                </form>
            </div>
        </>
    );
}
