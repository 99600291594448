import api from "../utils/api";

export default async function delet(url, header, query) {
    let res;
    await api
        .delete(url, {
            headers: header,
            params: query,
        })
        .then((response) => {
            res = { res: response.data, status: response.status };
        })
        .catch((err) => {
            res = { res: "error", status: err.response.status };
        });

    return { data: res.res, status: res.status };
}
