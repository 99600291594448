import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import TextareaAutosize from "react-textarea-autosize";

import Select from "react-select";

import { useStoreActions } from "easy-peasy";

import "./style.css";

import countries from "../../../utils/countries";

export default function Form() {
    const history = useHistory();

    const thunkSend = useStoreActions((action) => action.newFile.thunkSend);
    
    const apagarImagens = useStoreActions(
        (action) => action.newFile.actionApagar
    );

    const [form, setForm] = useState({
        pais: "",

        estado: "",

        ponto: "",

        desc: "",
    });

    const paisesMapped = countries.map((country) => {
        return {
            label: country.nome_pais,
            value: country.sigla,
        };
    });

    return (
        <div className="desktop-form-newpost-container">
            <div className="group">
                <Select
                    options={paisesMapped}
                    placeholder="País"
                    onChange={(e) => {
                        let valor = e.label;

                        setForm({ ...form, pais: valor });
                    }}
                ></Select>
            </div>

            <div className="group">
                <input
                    className="inp"
                    placeholder="Local"
                    type="text"
                    onChange={(e) => {
                        let valor = e.target.value;

                        setForm({ ...form, estado: valor });
                    }}
                />
            </div>

            <div className="group">
                <input
                    className="inp"
                    placeholder="Ponto Turístico"
                    type="text"
                    onChange={(e) => {
                        let valor = e.target.value;

                        setForm({ ...form, ponto: valor });
                    }}
                />
            </div>

            <div className="group">
                <TextareaAutosize
                    className="txt"
                    placeholder="Descrição"
                    onChange={(e) => {
                        let valor = e.target.value;

                        setForm({ ...form, desc: valor });
                    }}
                ></TextareaAutosize>
            </div>

            <div className="btn">
                <button
                    onClick={async () => {
                        await thunkSend(form);
                        
                        apagarImagens();

                        history.push("/feed");
                    }}
                >
                    Publicar
                </button>
            </div>
        </div>
    );
}
