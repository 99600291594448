import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

export default function Hero({ type, q }) {
    if (type === "feed") {
        return (
            <div className="desktop-hero-container">
                <p>
                    Aqui você compartilha
                    <br></br>suas melhores<br></br>
                    <b>VIAGENS</b>
                </p>
                <Link to="/new">Publicar</Link>
            </div>
        );
    }

    if (type === "search") {
        return (
            <div className="desktop-hero-container">
                <p>
                    Pesquisando por:<br></br>
                    <b>"{q}"</b>
                </p>
            </div>
        );
    }

    if (type === "top") {
        return (
            <div className="desktop-hero-container">
                <p>
                    Veja as <b>melhores</b> da semana
                </p>
            </div>
        );
    }
}
