import React from "react";

import { Link, useHistory } from "react-router-dom";

import { BsChevronLeft, BsSearch } from "react-icons/bs";

import { useForm } from "react-hook-form";

import "./style.css";

export default function Header({ tipo = "normal" }) {
    const { register, handleSubmit } = useForm();

    const history = useHistory();

    const submitData = (data) => {
        history.replace(`/search/${data.query}`);
    };

    return (
        <div className="header-container">
            <div className="left">
                <BsChevronLeft
                    onClick={() => {
                        history.goBack();
                    }}
                ></BsChevronLeft>
            </div>

            {tipo === "pesquisar" ? (
                <>
                    <div className="middle">
                        <form onSubmit={handleSubmit(submitData)}>
                            <input
                                type="text"
                                placeholder="Digite sua pesquisa..."
                                name="query"
                                ref={register({ required: true })}
                            />

                            <button type="submit">
                                <BsSearch></BsSearch>
                            </button>
                        </form>
                    </div>

                    <div className="right"></div>
                </>
            ) : null}

            {tipo === "normal" ? (
                <>
                    <div className="middle"></div>

                    <Link to="/top"></Link>
                </>
            ) : null}
        </div>
    );
}
