import React, { useState } from "react";

import { useHistory, Link } from "react-router-dom";

import { useForm } from "react-hook-form";

import { toast } from "react-toastify";

import { BsEye, BsEyeSlash } from "react-icons/bs";



//IMPORT SWR FUNCTIONS

import { login } from "../../../hooks/login";



import "./style.css";



import Logo from "../../../assets/logo_novo.png";

import LoadingOvl from "../../components/loading_ovl";



export default function LandingPage() {

    //DEFAULT HOOKS

    const [isLoading, setIsLoading] = useState(false);

    const [showPass, setShowPass] = useState(false);



    const history = useHistory();



    //FORM VALIDATION



    // INIT USE FORM HOOK

    const { register, errors, handleSubmit } = useForm();



    //HANDLE SUBMIT

    const useSubmit = async (data) => {

        setIsLoading(true);

        const res = await login(data.login.toLowerCase(), data.senha);



        if (res.status === 200) {

            toast.success(res.message, {

                position: "bottom-right",

                autoClose: 5000,

                hideProgressBar: false,

                closeOnClick: true,

                pauseOnHover: true,

                draggable: true,

                progress: undefined,

                toastId: res.status,

            });

            history.push("/feed");

        } else {

            setIsLoading(false);

            toast.error(res.message, {

                position: "top-right",

                autoClose: 5000,

                hideProgressBar: false,

                closeOnClick: true,

                pauseOnHover: true,

                draggable: true,

                progress: undefined,

                toastId: res.status,

            });

        }

    };



    return (

        <>

            {isLoading && <LoadingOvl></LoadingOvl>}

            <div className="desktop-landingPage-container">

                <div className="texts">

                    <img src={Logo} alt="Logo" />

                    <h1>My Best Trip</h1>

                    <h2>aqui você compartilha suas melhores viagens</h2>

                </div>

                <form onSubmit={handleSubmit(useSubmit)}>

                    <p>Usuário ou Email</p>

                    <input

                        className={errors.login ? "error" : ""}

                        type="text"

                        name="login"

                        ref={register({ required: true })}

                        placeholder="Digite seu usuário ou email"

                    />



                    <p>Senha</p>

                    <div

                        style={{

                            position: "relative",

                            width: "100%",

                            display: "flex",

                            justifyContent: "center",

                        }}

                    >

                        <input

                            className={errors.senha ? "error" : ""}

                            type={showPass ? "text" : "password"}

                            name="senha"

                            ref={register({ required: true })}

                            placeholder="**********"

                        />

                        <div className="eye">

                            {showPass ? (

                                <BsEyeSlash

                                    onClick={() => {

                                        setShowPass(false);

                                    }}

                                ></BsEyeSlash>

                            ) : (

                                <BsEye

                                    onClick={() => {

                                        setShowPass(true);

                                    }}

                                ></BsEye>

                            )}

                        </div>

                    </div>



                    {/* FORM ERRORS HANDLE */}

                    {errors.login || errors.senha ? (

                        <p className="error">

                            Por favor preencha todos os campos.

                        </p>

                    ) : null}



                    <button type="submit">Enviar</button>



                    <Link to="signin">Ainda não tem uma conta?</Link>

                    <Link to="lost-password">Esqueceu sua senha?</Link>

                </form>

            </div>

        </>

    );

}

