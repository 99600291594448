import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";



import LandingPage from "../desktop/pages/landingPage";

import Feed from "../desktop/pages/feed";

import FeedError from "../desktop/pages/feedError";

import Signin from "../desktop/pages/signin";

import LostPassword from "../desktop/pages/lostPassword";

import NewPassword from "../desktop/pages/newPassword";

import Profile from "../desktop/pages/profile";

import EditProfile from "../desktop/pages/editProfile";

import DropAcc from "../desktop/pages/dropAcc";

import NewPost from "../desktop/pages/newPost";

import Search from "../desktop/pages/search";

import Top from "../desktop/pages/top";



export default function MobileRoutes() {

    return (

        <BrowserRouter basename="/">

            <Switch>

                <Route path="/" exact component={LandingPage} />

                <Route path="/feed" exact component={Feed} />

                <Route path="/feed/error" exact component={FeedError} />

                <Route path="/signin" exact component={Signin} />

                <Route path="/lost-password" exact component={LostPassword} />

                <Route path="//new-password" exact component={NewPassword} />
                <Route path="/new-password" exact component={NewPassword} />

                <Route path="/profile/:id" exact component={Profile} />

                <Route path="/drop" exact component={DropAcc} />

                <Route path="/edit" exact component={EditProfile} />

                <Route path="/new" exact component={NewPost} />

                <Route path="/search/:query" component={Search} />

                <Route path="/top" component={Top} />

            </Switch>

        </BrowserRouter>

    );

}

