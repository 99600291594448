import React, { useState, useEffect } from 'react';

import { BsX } from 'react-icons/bs';

import Cookies from 'universal-cookie';

import Slider from "react-slick";



import './style.css'



import foto1 from '../../../assets/desktop-etapa1.png'

import foto2 from '../../../assets/desktop-etapa2.jpg'

import foto3 from '../../../assets/desktop-etapa3.jpg'

import foto4 from '../../../assets/desktop-etapa4.jpg'



export default function PopUp() {

    const cookies = new Cookies();



    const accept = cookies.get('pop_acc');


    const [isOpen, setIsOpen] = useState(false);

    const [isNewOpen, setIsNewOpen] = useState(false);



    function handleChangeAcc() {

        cookies.set('pop_acc', true, {

            path: "/",

            expires: new Date(new Date().setDate(new Date().getDate() + 7)),

        })

    }



    useEffect(() => {

        if(!accept === true || !accept === "true") {

            setIsOpen(true);

        }

    }, [accept])



    if(!isOpen) {

        return (<></>)

    }



    if(isNewOpen) {

        return (

            <>

                <div className="popup">

                    <div className="close">

                        <BsX onClick={() => {setIsOpen(false); setIsNewOpen(false); handleChangeAcc()}}></BsX>

                    </div>

                    <div className="content">

                        <Slider>

                            <img src={foto1} alt="Etapa 1" />

                            <img src={foto2} alt="Etapa 2" />

                            <img src={foto3} alt="Etapa 3" />

                            <img src={foto4} alt="Etapa 4" />

                        </Slider>

                    </div>

                </div>

                <div className="opacidade" onClick={() => {setIsOpen(false); setIsNewOpen(false); handleChangeAcc()}}></div>

            </>

        )

    }



    return (

        <div className="popup-pre-accept">

            <div className="close">

                <BsX onClick={() => {setIsOpen(false)}}></BsX>

            </div>

            <div className="content">

                <p>Deseja adicionar o My Best Trip à sua tela inicial?</p>

            </div>

            <div className="buttons">

                <button onClick={() => {setIsOpen(false); handleChangeAcc()}} className="grey-button">Fechar</button>

                <button onClick={() => {setIsNewOpen(true)}} className="red-button">Adicionar</button>

            </div>

        </div>

    )

}

