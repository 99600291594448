import post from "./post.fetch";
import getCookies from "./cookie";

export default async function comment(id_post, comment) {
    const { id, hash } = getCookies();
    //POST REQUEST TO /COMMENTS
    const { status } = await post(
        "/comments",
        { id, hash },
        { pubID: id_post, comment }
    );

    //WORK ON RESPONSES
    switch (status) {
        case 200:
            //SUCCESS
            return {
                status: 200,
                message: "Ok",
            };

        default:
            //ERRROR
            return {
                status: 999,
                message: "Ocorreu um erro. Tente novamente mais tarde.",
            };
    }
    // RETURN STRUC
    /* { 
        STATUS: NUMBER (HTTPS STATUS CODES),
        MESSAGE: STRING (MESSAGE FOR USER)
    } */
}
