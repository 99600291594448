import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { BsXCircleFill } from "react-icons/bs";

import "./style.css";

import deleteUser from "../../../hooks/deleteUser";

import Header from "../../components/header";

export default function DropAcc() {
    const history = useHistory();

    //FORM VALIDATION

    // INIT USE FORM HOOK
    const { register, errors, handleSubmit, setError } = useForm();

    //HANDLE SUBMIT
    const useSubmit = async (data) => {
        if (data.login.toLowerCase() === "confirmar") {
            const res = await deleteUser();

            if (res.status === 200) {
                toast.success(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: res.status,
                });
                history.push(`/`);
            } else {
                toast.error(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: res.status,
                });
            }
        } else {
            setError("login", {
            type: "manual"
            });
        }
    };

    //PUT API VALUE ON INPUTS

    return (
        <>
            <Header></Header>
            <div className="desktop-landingPage-container">
                <form
                    style={{ minWidth: "400px" }}
                    onSubmit={handleSubmit(useSubmit)}
                >
                    <BsXCircleFill
                        onClick={() => history.goBack()}
                        className="icon-close-profile"
                    ></BsXCircleFill>
                    <h2>Apagar conta</h2>

                    <h5>
                        Para apagar sua conta por favor digite "confirmar" no
                        campo abaixo
                    </h5>
                    <input
                        className={errors.login ? "error" : ""}
                        type="text"
                        name="login"
                        ref={register({ required: true })}
                    />

                    {errors.login ? (
                        <p className="error">
                            Você não preencheu corretamente. Para apagar escreva "confirmar" no campo.<br></br>
                        </p>
                    ) : null}

                    <button type="submit">Apagar</button>

                    <Link to="/edit">Deseja voltar a edição de perfil?</Link>
                </form>
            </div>
        </>
    );
}
