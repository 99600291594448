import React from "react";
import { BsTrashFill } from "react-icons/bs";

import "./style.css";

import getCookies from "../../../../../hooks/cookie";
import { url } from "../../../../../utils/vars";
import deleteComm from "../../../../../hooks/deleteComm";

export default function Lista({ comment }) {
    const { id } = getCookies();
    function mapComm(comm) {
        return comm.map((data) => {
            return (
                <div className="comm-ind">
                    <div className="left">
                        <img
                            alt="profile"
                            src={`${url}/${data.authorData.picture}`}
                        />
                    </div>

                    <div className="right">
                        <h3>@{data.authorData.username}</h3>

                        <p>{data.comment.comment}</p>
                    </div>

                    <div className="delete-comm">
                        {id === data.comment.authorID && (
                            <BsTrashFill
                                onClick={() => {
                                    const conf =
                                        window.confirm("Você tem certeza?");
                                    if (conf) {
                                        deleteComm(data.comment.id);
                                    }
                                }}
                            ></BsTrashFill>
                        )}
                    </div>
                </div>
            );
        });
    }

    return (
        <div className="lista-comentarios-container">{mapComm(comment)}</div>
    );
}
