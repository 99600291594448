import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

export default function ErrorOverlay({ error }) {
    if (error === "deslogado") {
        return (
            <div className="desktop-error-ovl">
                <p>Você precisar estar logado.</p>
                <Link to="/">Fazer login</Link>
            </div>
        );
    }

    if (error === 404) {
        return (
            <div className="desktop-error-ovl">
                <h1>404</h1>
                <p>Não encontrado.</p>
            </div>
        );
    }

    if (error === 999) {
        return (
            <div className="desktop-error-ovl">
                <h1>Ocorreu um erro.</h1>
                <p>Tente novamente mais tarde.</p>
            </div>
        );
    }

    if (error === "feed_sem") {
        return (
            <div className="desktop-error-ovl">
                <h1 style={{ textAlign: "center" }}>
                    Olá, parece que você não<br></br>tem posts para ver.
                </h1>
                <p>
                    Siga mais pessoas ou veja os posts mais votados da semana.
                </p>
                <div>
                    <Link className="btn" to="/top">
                        Top posts da semana
                    </Link>
                </div>
            </div>
        );
    }
}
