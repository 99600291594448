import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { url } from "../../../utils/vars";

import Share from "../../../mobile/components/share";
import { FiShare2 } from "react-icons/fi";

export default function ProfileDesc({ data, id, idLogado, handleFollow }) {
    const history = useHistory();
    const [seguindo, setSeguindo] = useState(true);
    const [openShare, setOpenShare] = useState(false);

    useEffect(() => {
        if (data.followers.split(",").includes(idLogado)) {
            setSeguindo(true);
        } else {
            setSeguindo(false);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="row">
                <div className="left">
                    <img src={`${url}${data.picture}`} alt="" />
                    <div className="name">
                        <h1>{data.name}</h1>
                        <h2>@{data.username}</h2>
                    </div>
                </div>
                <div className="right">
                    <div className="contador">
                        <h3>
                            {data.following === ""
                                ? 0
                                : data.following.split(",").length - 1}
                        </h3>
                        <p>Seguindo</p>
                    </div>
                    <div className="contador">
                        <h3>
                            {data.followers === ""
                                ? 0
                                : data.followers.split(",").length - 1}
                        </h3>
                        <p>Seguidores</p>
                    </div>
                    <div className="contador">
                        <h3>{data.score ? data.score : 0}</h3>
                        <p>Pontos</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="left2">
                    <p>{data.description}</p>
                </div>
                <div className="right2">
                    {id === idLogado ? (
                        <div className="btn-meu">
                            <button
                                onClick={() => {
                                    history.push(`/edit`);
                                }}
                            >
                                Editar
                            </button>
                            <button onClick={() => {}}>
                                <FiShare2
                                    onClick={() => setOpenShare(!openShare)}
                                ></FiShare2>
                            </button>
                        </div>
                    ) : (
                        <>
                            {seguindo ? (
                                <button
                                    onClick={() => {
                                        handleFollow();
                                        setSeguindo(false);
                                    }}
                                    className="seguindo"
                                >
                                    Seguindo
                                </button>
                            ) : (
                                <button
                                    onClick={() => {
                                        handleFollow();
                                        setSeguindo(true);
                                    }}
                                >
                                    Seguir
                                </button>
                            )}
                        </>
                    )}
                    {openShare && <Share></Share>}
                </div>
            </div>
        </>
    );
}
