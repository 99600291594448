import React from "react";



import { useHistory, Link, Redirect } from "react-router-dom";



import useSWR from "swr";



import "./style.css";



//IMPORT GET COOKIES FUNCTION



import getCookies from "../../../hooks/cookie";



//IMPORT API URL



//IMPORT FEED SWR HOOK



import get from "../../../hooks/get.fetch";



import LoadingOvl from "../../components/loading_ovl";



import MenuInf from "../../components/menu_inferior";



import ErrorOvl from "../../components/error_ovl";



import ListaPosts from "../../components/lista_posts";

import Popup from "../../components/popup";





export default function Feed() {

    const { id, hash } = getCookies();



    const history = useHistory();



    //SE O USUÁRIO NÃO ESTIVER LOGADO



    if (!id || !hash) {

        history.push("/feed/error");

    }



    const { data, error } = useSWR(["/feed", id], (url, id) =>

        get(url, { id, hash }, { id })

    );



    if (error) {

        return (

            <>

                <ErrorOvl error={999}></ErrorOvl>



                <div className="bg_full"></div>

            </>

        );

    }



    if (!data) {

        return (

            <>

                <LoadingOvl></LoadingOvl>



                <div className="bg_full"></div>

            </>

        );

    }



    if (data.posts.length === 0) {

        return (

            <>

                <Popup></Popup>

                <MenuInf bg="none"></MenuInf>



                <Redirect to="/top"></Redirect>



                <div className="bg_full"></div>

            </>

        );

    }



    //SE O USUÁRIO ESTIVER LOGADO



    return (

        <>

            <div>

                <Popup></Popup>

                <ListaPosts dados={data.posts} type="normal"></ListaPosts>

            </div>

        </>

    );

}

