import React from "react";



import useSWR from 'swr'



import { Link } from "react-router-dom";



import Masonry from "react-masonry-css";



import ReactLoading from "react-loading";

import invertArr from "../../../hooks/invertArray";

import get from "../../../hooks/get.fetch";



import "./style.css";



import { url } from "../../../utils/vars";



export default function PostList({ id, eu }) {

    let { data, error } = useSWR(["/posts", id], (url, id) =>

            get(url, {}, { id_search: id })

        );



    if (error) {

        return (

            <h1 style={{ textAlign: "center" }}>

                Ocorreu um erro.<br></br>Tente novamente mais tarde.

            </h1>

        );

    }



    if (!data) {

        return (

            <div className="is-loading">

                <ReactLoading

                    className="load"

                    type="bubbles"

                    color="#e82f3e"

                    width={100}

                />

            </div>

        );

    }



    if (data.length === 0) {

        return (

            <h3 style={{ textAlign: "center", marginTop: "16px" }}>

                Sem posts

            </h3>

        );

    }



    function mapFotos(data) {

        return data.map((d) => {

            console.log(d);



            return (

                <div key={d.id} className="photo">

                    <Link to={`/post/${d.data.pub}?profile=${eu}`}>

                        <img src={`${url}${d.pictures[0]}`} alt="Post" />

                    </Link>

                </div>

            );

        });

    }

    return (

        <div className="mob-post-list-container">

            <Masonry

                breakpointCols={3}

                className="mob-post-list-grid"

                columnClassName="mob-post-list-grid_column"

                style={{ paddingBottom: "80px" }}

            >

                {mapFotos(invertArr(data))}

            </Masonry>

        </div>

    );

}

