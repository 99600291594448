import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./style.css";

import Logo from "../../../assets/logo_novo.png";
import LoadingOvl from "../../components/loading_ovl";
import lostPass from "../../../hooks/lostPass.swr";

export default function LostPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    //FORM VALIDATION

    // INIT USE FORM HOOK
    const { register, errors, handleSubmit } = useForm();

    //HANDLE SUBMIT
    const useSubmit = async (data) => {
        setIsLoading(true);
        const res = await lostPass(data.email);

        if (res.status === 200) {
            toast.success("Foi enviado um link para seu email. Por favor aguarde alguns minutos.", {
                position: "top-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: res.status,
            });
            setIsLoading(false);
            history.push("/");
        } else {
            setIsLoading(false);
            toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: res.status,
            });
        }
    };

    return (
        <>
            {isLoading && <LoadingOvl></LoadingOvl>}
            <div className="desktop-landingPage-container">
                <div className="texts">
                    <img src={Logo} alt="Logo" />
                    <h1>My Best Trip</h1>
                    <h2>aqui você compartilha suas melhores viagens</h2>
                </div>

                <form onSubmit={handleSubmit(useSubmit)}>
                    <p>Email</p>
                    <input
                        type="text"
                        name="email"
                        placeholder="johndoe@gmail.com"
                        className={errors.email && "error"}
                        ref={register({ required: true })}
                    />

                    <button type="submit">Enviar</button>

                    {errors.email ? (
                        <p className="error">
                            Preencha todos os campos corretamente.<br></br>
                        </p>
                    ) : null}
                    <Link to="/">Quer voltar para o login?</Link>
                </form>
            </div>
        </>
    );
}
