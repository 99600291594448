import React, { useState, useEffect } from "react";

import { FiShare2 } from "react-icons/fi";

import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import "./style.css";

import { url } from "../../../utils/vars";

import getCookies from "../../../hooks/cookie";

import follow from "../../../hooks/follow.swr";
import Share from "../share";

export default function Resume({ eu, dados }) {
    const history = useHistory();

    const [seguindo, setSeguindo] = useState(true);
    const [openShare, setOpenShare] = useState(false);

    const { id } = getCookies();

    useEffect(() => {
        if (dados.followers.split(",").includes(id)) {
            setSeguindo(true);
        } else {
            setSeguindo(false);
        }
        // eslint-disable-next-line
    }, []);

    function handleFollow() {
        follow(dados.refID).then((res) => {
            if (!res.status === 200) {
                toast.error(res.message, {
                    position: "top-right",

                    autoClose: 5000,

                    hideProgressBar: false,

                    closeOnClick: true,

                    pauseOnHover: true,

                    draggable: true,

                    progress: undefined,

                    toastId: res.status,
                });
            }
        });
    }

    return (
        <div className="resume-perfil-container">
            <div className="photo">
                <img alt="profile" src={`${url}${dados.picture}`} />
            </div>

            <div className="basic">
                <h1>{dados.name}</h1>

                <h2>@{dados.username}</h2>
            </div>

            <div className="numeros">
                <div className="numero">
                    <h3>{dados.following.split(",").length - 1}</h3>

                    <p>Seguindo</p>
                </div>

                <div className="numero">
                    <h3>{dados.followers.split(",").length - 1}</h3>

                    <p>Seguidores</p>
                </div>

                <div className="numero">
                    <h3>{dados.score === null ? 0 : dados.score}</h3>

                    <p>Pontos</p>
                </div>
            </div>

            {!eu ? (
                <>
                    {seguindo ? (
                        <div className="btn seg">
                            <button
                                onClick={async () => {
                                    handleFollow();

                                    setSeguindo(false);
                                }}
                            >
                                seguindo
                            </button>
                        </div>
                    ) : (
                        <div className="btn">
                            <button
                                onClick={async () => {
                                    handleFollow();

                                    setSeguindo(true);
                                }}
                            >
                                seguir
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <div className="btn-meu">
                    <button
                        onClick={() => {
                            history.push("/edit");
                        }}
                    >
                        Editar
                    </button>

                    <button onClick={() => {}}>
                        <FiShare2
                            onClick={() => setOpenShare(!openShare)}
                        ></FiShare2>
                    </button>
                </div>
            )}
            {openShare && <Share></Share>}
            <div className="resumo">
                <p>{dados.description}</p>
            </div>
        </div>
    );
}
