import React from "react";
import ReactLoading from "react-loading";

import "./style.css";

export default function LoadingOverlay() {
    return (
        <div className="desktop-loading-ovl">
            <ReactLoading
                className="load"
                type="bubbles"
                color="#e82f3e"
                width={150}
            />
            <p>Carregando...</p>
        </div>
    );
}
