import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createStore, StoreProvider } from "easy-peasy";
import model from "./model/model";

// import swDev from './swDev';

const store = createStore(model);

ReactDOM.render(
    <React.StrictMode>
        <StoreProvider store={store}>
            <App />
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
// swDev();
