import Cookies from "universal-cookie";

export default function changeHash(id, hash) {
    const cookies = new Cookies();

    cookies.set("id", id, {
        path: "/",
        expires: new Date(new Date().setDate(new Date().getDate() + 7)),
    });
    cookies.set("hash", hash, {
        path: "/",
        expires: new Date(new Date().setDate(new Date().getDate() + 7)),
    });
}
