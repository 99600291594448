import React, { useState } from "react";
import Slider from "react-slick";
import Masonry from "react-masonry-css";

import { BsStar } from "react-icons/bs";

import Trofeu from "../../../assets/trofeu.png";
import Medalha from "../../../assets/medalha.png";

import "./style.css";

import { url } from "../../../utils/vars";

import Pub from "../pub";

function PostCard({ post, classif }) {
    const [aberto, setAberto] = useState(false);
    const [pubId, setPubId] = useState(false);

    let imagem;

    if (classif === 0) {
        imagem = <img alt="trofeu" src={Trofeu} />;
    } else {
        imagem = <img alt="medalha" src={Medalha} />;
    }

    function mapPhotos(photos) {
        return photos.map((photo) => {
            return (
                <div className="photo-tog">
                    <img src={`${url}${photo}`} className="top" alt="" />
                    {/* <img src={`${url}/${photo}`} className="bottom" /> */}
                </div>
            );
        });
    }

    return (
        <>
            {aberto && (
                <Pub
                    id={pubId}
                    onClose={() => {
                        setAberto(false);
                    }}
                ></Pub>
            )}
            <div className="desktop-postonlist-container">
                <div className="photos">
                    {classif !== "none" ? (
                        <div className="classif">
                            {imagem}
                            <p>#{classif + 1} do Mundo</p>
                        </div>
                    ) : null}
                    <Slider arrows={true} infinite={false} dots={true}>
                        {mapPhotos(post.pictures)}
                    </Slider>
                </div>
                <div className="desc">
                    <p className="place">{post.data.place}</p>
                    <p className="description">{post.data.description}</p>
                </div>
                <div className="ver-mais">
                    <button
                        onClick={() => {
                            setAberto(true);
                            setPubId(post.data.pub);
                        }}
                    >
                        Ver Mais
                    </button>
                </div>
                <div className="autor">
                    <div className="left">
                        <img src={`${url}${post.authorData.picture}`} alt="" />
                        <div>
                            <p className="name">{post.authorData.name}</p>
                            <p className="username">
                                @{post.authorData.username}
                            </p>
                        </div>
                    </div>
                    <div className="right">
                        <BsStar></BsStar>
                        <p>
                            {post.data.score === "" ? 0 : post.data.score}{" "}
                            Pontos
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default function ListaPosts({ dados, type = "normal" }) {
    function mapPosts(dados) {
        if (type === "top") {
            return dados.map((post, key) => {
                return <PostCard post={post} classif={key}></PostCard>;
            });
        } else {
            return dados.map((post) => {
                return <PostCard post={post} classif="none"></PostCard>;
            });
        }
    }

    return (
        <div className="desktop-listaposts-container">
            <Masonry
                breakpointCols={{ default: 4, 1300: 2, 1400: 3 }}
                className="post-list-grid"
                columnClassName="post-list-grid_column"
            >
                {mapPosts(dados)}
            </Masonry>
        </div>
    );
}
