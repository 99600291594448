import { thunk, action } from "easy-peasy";
import api from "../../utils/api";

const newFile = {
    paises: [{ label: "Loading" }],
    estados: [{ label: "Sem estado" }],

    actionLoadFeed: action((state, payload) => {
        return {
            estados: [],
            paises: payload,
        };
    }),

    actionLoadEstados: action((state, payload) => {
        if (payload.dados.length === 0) {
            return {
                ...payload.state,
                estados: [{ label: "Sem estado", value: "Sem estado" }],
            };
        }

        return {
            ...payload.state,
            estados: payload.dados,
        };
    }),

    thunkLoadPaises: thunk(async (actions, payload, { getState }) => {
        const res = await api.get(
            "https://api.countrystatecity.in/v1/countries",
            {
                headers: {
                    "X-CSCAPI-KEY":
                        "a0JjWDBkd1VnaElHOE54RDVTc1pIVDR3MDJNYjRaTE9HblRWR0NJVw==",
                },
            }
        );

        const estadoNovo = res.data.map((pais) => {
            return {
                label: pais.name,
                value: pais.iso2,
            };
        });

        return actions.actionLoadFeed(estadoNovo);
    }),

    thunkLoadEstados: thunk(async (actions, payload, { getState }) => {
        const state = getState((state) => state);

        const res = await api.get(
            `https://api.countrystatecity.in/v1/countries/${payload}/states`,
            {
                headers: {
                    "X-CSCAPI-KEY":
                        "a0JjWDBkd1VnaElHOE54RDVTc1pIVDR3MDJNYjRaTE9HblRWR0NJVw==",
                },
            }
        );

        console.log(res);

        const estadonovo = res.data.map((estado) => {
            return {
                label: estado.name,
                value: estado.name,
            };
        });

        return actions.actionLoadEstados({ state, dados: estadonovo });
    }),
};

export default newFile;
