import post from "./postPhoto.fetch";



import Cookies from "universal-cookie";



import { login } from "./login";



import CryptoJS from "crypto-js";



export default async function signIn(userData, photo) {

    const cookies = new Cookies();



    //RECEIVE ALL THE FORM DATA AND THE SELECTED PHOTO



    const password_hash = CryptoJS.SHA3(userData.senha, {

        outputLength: 512,

    }).toString();



    //REQUEST TO API FOR USER DATA



    const { status, data } = await post(

        "/users",



        {},



        {

            username: userData.user.toLowerCase(),



            name: userData.nome,



            email: userData.email,



            cellphone: userData.celular,



            description: userData.desc,



            password: password_hash,

        }

    );



    const con = data.unique_constraint;

    let constraint;

    if(con === "username") {

        constraint = "Usuário"

    } else if(con === "email") {

        constraint = "Email"

    } else {

        constraint = "Telefone"

    }

        

    //HANDLE STATUS CODES



    switch (status) {

        case 200:

            //USER WAS CREATED



            break;


        case 201:

                //USER WAS CREATED
    
    
    
                break;



        case 400:

            //MISSING ANY FIELD



            return {

                status: 400,



                message: "Por favor preencha todos os campos corretamente.",

            };



        case 403:

            //USER CAN'T BE CREATED



            return {

                status: 403,



                message: `${constraint} já existe no sistema.`,

            };



        default:

            //NON CONTROLLED ERROR



            return {

                status: 999,



                message: "Não 👍.",

            };

    }



    const { status: statusLogin } = await login(userData.user, userData.senha);



    if (statusLogin !== 200) {

        return {

            status: 999,



            message: "Ocorreu um erro. Tente novamente mais tarde. 2",

        };

    }



    const id = cookies.get("id");



    const hash = cookies.get("hash");



    let formData = new FormData();



    formData.append("img", photo);



    //REQUEST TO API FOR USER PHOTO



    const { status: statusPhoto } = await post(

        "/users/uploads",



        {

            "Content-Type": "multipart/form-data",



            id,



            hash,

        },



        formData

    );



    //HANDLE STATUS CODES



    switch (statusPhoto) {

        case 200:

            //SUCESS



            return {

                status: 200,



                message: "OK 👍",

            };



        default:

            //ERROR



            return {

                status: 999,



                message: "Ocorreu um erro. Tente novamente mais tarde.",

            };

    }



    /*RETURN STRUC {



        STATUS: NUMBER (HTTPS STATUS CODE),



        MESSAGE STRING



    }*/

}

