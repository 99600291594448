import delet from "./delete.fetch";
import Cookies from "universal-cookie";

export default async function deleteUsers() {
    //GET AUTH
    const cookies = new Cookies();
    const id = cookies.get("id");
    const hash = cookies.get("hash");

    //MAKE REQUEST TO /RATE
    const res = await delet("/users/delete", { id, hash }, {});

    //HANDLE STATUS CODE

    switch (res.status) {
        case 200:
            //SUCCESS
            return {
                status: 200,
                message: "Usuário apagado com sucesso.",
            };

        case 404:
            //POST DOESN'T EXIST
            return {
                status: 404,
                message: "Esse usuário não existe mais.",
            };

        default:
            //NON-CONTROLLED ERROR
            return {
                status: 999,
                message: "Ocorreu um erro. Tente novamente mais tarde.",
            };
    }

    /*
        RETURN STRUC {
            STATUS: NUMBER (HTTPS STATUS CODE),
            MESSAGE: STRING
        }
    */
}
