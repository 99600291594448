import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";



import LandingPage from "../mobile/pages/landingPage";

import LostPassword from "../mobile/pages/lostPassword";

import NewPassword from "../mobile/pages/newPassword";

import SignIn from "../mobile/pages/signin";

import Feed from "../mobile/pages/feed";

import Profile from "../mobile/pages/profile";

import EditProfile from "../mobile/pages/editProfile";

import DropAcc from "../mobile/pages/dropAcc";

import Notification from "../mobile/pages/notifications";

import Post from "../mobile/pages/post";

import Top from "../mobile/pages/top";

import Search from "../mobile/pages/search";

import SearchNone from "../mobile/pages/search_none";

import NewPost from "../mobile/pages/newPost";



export default function MobileRoutes() {

    return (

        <BrowserRouter basename="/">

            <Switch>

                <Route path="/" exact component={LandingPage} />

                <Route path="/lost-password" exact component={LostPassword} />

                <Route path="//new-password" exact component={NewPassword} />
                <Route path="/new-password" exact component={NewPassword} />

                <Route path="/feed" exact component={Feed} />

                <Route path="/signin" exact component={SignIn} />

                <Route path="/profile/:id" exact component={Profile} />

                <Route path="/drop" exact component={DropAcc} />

                <Route path="/edit" exact component={EditProfile} />

                <Route path="/notifications" exact component={Notification} />

                <Route path="/post/:id" exact component={Post} />

                <Route path="/new" exact component={NewPost} />

                <Route path="/search/:query" component={Search} />

                <Route path="/search" component={SearchNone} />

                <Route path="/top" component={Top} />

            </Switch>

        </BrowserRouter>

    );

}

