import React from "react";
import { useHistory } from "react-router-dom";
import useSWR from "swr";

import "./style.css";

//IMPORT GET COOKIES FUNCTION
import getCookies from "../../../hooks/cookie";

//IMPORT API URL

//IMPORT FEED SWR HOOK
import get from "../../../hooks/get.fetch";

import LoadingOvl from "../../components/loading_ovl";
import ErrorOvl from "../../components/error_ovl";
import ListaPosts from "../../components/lista_posts_top";
import MenuInferior from "../../components/menu_inferior";

export default function Top() {
    const { id, hash } = getCookies();
    const history = useHistory();

    //SE O USUÁRIO NÃO ESTIVER LOGADO
    if (!id || !hash) {
        history.push("/feed/error");
    }

    const { data, error } = useSWR(["/posts/topRated", id], (url, id) =>
        get(url, { id, hash }, {})
    );

    if (error) {
        return (
            <>
                <ErrorOvl error={999}></ErrorOvl>
                <div className="bg_full"></div>
            </>
        );
    }

    if (!data) {
        return (
            <>
                <LoadingOvl></LoadingOvl>
                <div className="bg_full"></div>
            </>
        );
    }

    if (data.length === 0) {
        return (
            <>
                <MenuInferior bg="none"></MenuInferior>
                <ErrorOvl error="feed_sem"></ErrorOvl>
                <div className="bg_full"></div>
            </>
        );
    }

    //SE O USUÁRIO ESTIVER LOGADO
    return (
        <>
            <div>
                <ListaPosts dados={data} type="normal"></ListaPosts>
            </div>
        </>
    );
}
