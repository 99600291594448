import React, { useState, useRef, useEffect } from "react";

import { useForm } from "react-hook-form";

import TextareaAutosize from "react-textarea-autosize";

import Cookies from "universal-cookie";

import { toast } from "react-toastify";

import { Link, useHistory } from "react-router-dom";

import { BsEye, BsEyeSlash, BsXCircleFill } from "react-icons/bs";



import "./style.css";



import SemFoto from "../../../assets/semfoto.jpg";

import { url } from "../../../utils/vars";



import editProfile from "../../../hooks/editProfile";

import get from "../../../hooks/get.fetch";



import Header from "../../components/header";



export default function EditProfile() {

    const cookies = new Cookies();

    const id = cookies.get("id");

    const hash = cookies.get("hash");

    const input = useRef(null);

    const history = useHistory();



    //SELECT AND PREVIEW IMAGE



    const [selectedPic, setSelectedPic] = useState(undefined);

    const [previewImage, setPreviewImage] = useState(undefined);

    const [changed, setChanged] = useState(false);

    const [showPass, setShowPass] = useState(false);



    function selectImage(img) {

        setSelectedPic(img[0]);

        setPreviewImage(URL.createObjectURL(img[0]));

    }



    //FORM VALIDATION



    // INIT USE FORM HOOK

    const { register, errors, handleSubmit, setValue } = useForm();



    //HANDLE SUBMIT

    const useSubmit = async (data) => {

        const res = await editProfile(selectedPic, data, changed);



        if (res.status === 200) {

            toast.success(res.message, {

                position: "top-right",

                autoClose: 5000,

                hideProgressBar: false,

                closeOnClick: true,

                pauseOnHover: true,

                draggable: true,

                progress: undefined,

                toastId: res.status,

            });

            history.push(`/profile/${id}`);

        } else {

            toast.error(res.message, {

                position: "top-right",

                autoClose: 5000,

                hideProgressBar: false,

                closeOnClick: true,

                pauseOnHover: true,

                draggable: true,

                progress: undefined,

                toastId: res.status,

            });

        }

    };



    //REQUEST DATA FROM API



    useEffect(() => {

        get(

            "/users",

            {

                id,

                hash,

            },

            {

                id,

            }

        ).then((res) => {

            setValue("desc", res.description);

            setValue("nome", res.name);

            setValue("user", res.username);

            setSelectedPic(res.picture);

            setPreviewImage(`${url}${res.picture}`);

        });

        // eslint-disable-next-line

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //PUT API VALUE ON INPUTS



    return (

        <>

            <Header></Header>

            <div className="desktop-landingPage-container">

                <form

                    style={{ minWidth: "400px", margin: "70px 0" }}

                    onSubmit={handleSubmit(useSubmit)}

                >

                    <BsXCircleFill

                        onClick={() => history.goBack()}

                        className="icon-close-profile"

                    ></BsXCircleFill>

                    <h2>Editar Perfil</h2>

                    <br></br>

                    <input

                        ref={input}

                        style={{ display: "none" }}

                        type="file"

                        onChange={(a) => {

                            selectImage(a.target.files);

                            setChanged(true);

                        }}

                    />

                    <img

                        className="big"

                        onClick={() => {

                            input.current.click();

                        }}

                        src={previewImage ? previewImage : SemFoto}

                        alt=""

                    />



                    <p>Nome Completo</p>

                    <input

                        type="text"

                        name="nome"

                        placeholder="Ex: John Doe"

                        className={errors.nome && "error"}

                        ref={register({ required: false })}

                    />



                    <p>Usuário</p>

                    <input

                        type="text"

                        name="user"

                        placeholder="Ex: johndoe"

                        className={errors.user && "error"}

                        ref={register({ required: false })}

                    />



                    <p>Email</p>

                    <input

                        type="text"

                        name="email"

                        placeholder="johndoe@gmail.com"

                        className={errors.email && "error"}

                        ref={register({ required: false })}

                    />



                    <p>Celular</p>

                    <input

                        type="text"

                        name="celular"

                        placeholder="11999999999"

                        className={errors.celular && "error"}

                        ref={register({ required: false })}

                    />



                    <p>Senha</p>

                    <div

                        style={{

                            position: "relative",

                            width: "100%",

                            display: "flex",

                            justifyContent: "center",

                        }}

                    >

                        <input

                            className={errors.senha ? "error" : ""}

                            type={showPass ? "text" : "password"}

                            name="senha"

                            ref={register({ required: false })}

                            placeholder="**********"

                        />

                        <div className="eye">

                            {showPass ? (

                                <BsEyeSlash

                                    onClick={() => {

                                        setShowPass(false);

                                    }}

                                ></BsEyeSlash>

                            ) : (

                                <BsEye

                                    onClick={() => {

                                        setShowPass(true);

                                    }}

                                ></BsEye>

                            )}

                        </div>

                    </div>



                    <p>Descrição</p>

                    <TextareaAutosize

                        maxLength={150}

                        type="text"

                        name="desc"

                        className={errors.desc && "error"}

                        ref={register({ required: false })}

                    />



                    {errors.desc ? (

                        <p className="error">

                            Preencha todos os campos corretamente.<br></br>

                        </p>

                    ) : null}



                    <button type="submit">Confirmar Edição</button>



                    <Link to={`drop`}>Deseja cancelar sua conta?</Link>

                </form>

            </div>

        </>

    );

}

