import React from "react";

import "./style.css";

import MenuInf from "../../components/menu_inferior";
import Header from "../../components/header";

export default function Top() {
    return (
        <>
            <div>
                <Header tipo="pesquisar"></Header>

                <MenuInf bg=""></MenuInf>
            </div>
        </>
    );
}
