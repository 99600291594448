import api from "../utils/api";

export default async function put(url, header, query) {
    let res;
    await api
        .put(
            url,
            { ...query },
            {
                headers: header,
            }
        )
        .then((response) => {
            res = { res: response.data, status: response.status };
        })
        .catch((err) => {
            res = { res: "error", status: err.response.status };
        });

    console.log(res);

    return { data: res.res, status: res.status };
}
