import React from "react";

import { useParams } from "react-router-dom";

import Cookies from "universal-cookie";

import useSWR from "swr";

import MenuInf from "../../components/menu_inferior";

import Header from "../../components/header";

import Resume from "../../components/perfil_resume";

import Entry from "../../components/perfil_entry-photos";

import PostList from "../../components/posts_list";

import LoadingOvl from "../../components/loading_ovl";

import ErrorOvl from "../../components/error_ovl";

import get from "../../../hooks/get.fetch";

import "./style.css";

export default function Perfil() {
    const cookies = new Cookies();

    const { id } = useParams();

    const idLogado = cookies.get("id");

    const hash = cookies.get("hash");

    const eu = id === idLogado;

    const { data, error } = useSWR(["/users", id], (url, id) =>
        get(url, { id: idLogado, hash }, { id })
    );

    if (error) {
        return (
            <>
                <ErrorOvl error={404}></ErrorOvl>

                <div className="bg_full"></div>

                <MenuInf bg="none"></MenuInf>
            </>
        );
    }

    if (!data) {
        return (
            <>
                <LoadingOvl></LoadingOvl>

                <div className="bg_full"></div>

                <MenuInf bg="none"></MenuInf>
            </>
        );
    }

    return (
        <>
            <Header></Header>

            <div className="perfil-container">
                <Resume eu={eu} dados={data}></Resume>

                <Entry></Entry>

                <PostList eu={eu} id={id}></PostList>
            </div>

            <MenuInf bg="full"></MenuInf>
        </>
    );
}
