import React from "react";
import { BsStar, BsStarFill } from "react-icons/bs";
import Rating from "react-rating";
import { toast } from "react-toastify";

import "./style.css";

import like from "../../../hooks/like";

export default function Like({ id_post, onCl = () => {} }) {
    async function giveLike(id, rating) {
        const res = await like(id, rating);

        if (res.status !== 200) {
            toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: res.status,
            });
        } else {
            toast.success("Voto computado com sucesso", {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: res.status,
            });
        }
    }

    return (
        <>
            <div className="container-like">
                <Rating
                    onClick={(val) => {
                        giveLike(id_post, val);
                        onCl();
                    }}
                    direction="rtl"
                    emptySymbol={<BsStar></BsStar>}
                    fullSymbol={<BsStarFill></BsStarFill>}
                ></Rating>
            </div>
            <div
                onClick={() => setTimeout(onCl(), 1000)}
                className="ovl-container-like"
            ></div>
        </>
    );
}
