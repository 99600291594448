import React from "react";

import useSWR, { mutate as mutateGlobal } from "swr";

import { useForm } from "react-hook-form";

import { toast } from "react-toastify";

import Slider from "react-slick";

import {

    BsTrashFill,

    BsStar,

    BsStarFill,

    BsArrowRightShort,

    BsX,

} from "react-icons/bs";

import Rating from "react-rating";

import { Link } from "react-router-dom";



import "./style.css";



import getCookies from "../../../hooks/cookie";

import { url } from "../../../utils/vars";

import get from "../../../hooks/get.fetch";

import comment from "../../../hooks/comment";

import deletePost from "../../../hooks/deletePost";

import deleteComm from "../../../hooks/deleteComm";

import like from "../../../hooks/like";



export default function Pub({ id: id_post, onClose = () => {} }) {

    const { id, hash } = getCookies();



    const { register, errors, handleSubmit } = useForm();



    const { data, error } = useSWR(["/post", id_post], (url, id_post) =>

        get(url, { id, hash }, { pubID: id_post }, { refreshInterval: 1000 })

    );



    const {

        data: dataComm,

        error: errorComm,

        mutate,

    } = useSWR(["/comments", id_post], (url, id_post) =>

        get(url, { id, hash }, { id: id_post }, { refreshInterval: 1000 })

    );



    if (error || errorComm) {

        <h1>Error</h1>;

    }



    if (!data || !dataComm) {

        return <h1>Loading</h1>;

    }



    const useSubmit = async (data) => {

        const res = await comment(id_post, data.query);



        if (res.status !== 200) {

            toast.error(res.message, {

                position: "top-right",

                autoClose: 5000,

                hideProgressBar: false,

                closeOnClick: true,

                pauseOnHover: true,

                draggable: true,

                progress: undefined,

                toastId: res.status,

            });

        }



        let newData = dataComm;



        newData.push({

            authorData: {

                picture: "/uploads/default.png",

                username: "Eu",

            },

            comment: {

                comment: data.query,

            },

        });



        mutate();

    };



    function deletepost(id) {

        const accept = window.confirm(

            "Você tem certeza que quer apagar essa publicação? (NÃO PODE SER REVERTIDO)"

        );



        if (accept) {

            const res = deletePost(id);



            if (res.status === 200) {

                toast.success(res.message, {

                    position: "bottom-right",

                    autoClose: 5000,

                    hideProgressBar: false,

                    closeOnClick: true,

                    pauseOnHover: true,

                    draggable: true,

                    progress: undefined,

                    toastId: res.status,

                });

            } else {

                toast.error(res.message, {

                    position: "bottom-right",

                    autoClose: 5000,

                    hideProgressBar: false,

                    closeOnClick: true,

                    pauseOnHover: true,

                    draggable: true,

                    progress: undefined,

                    toastId: res.status,

                });

            }

        }

    }



    async function giveLike(id, rating) {

        const res = await like(id, rating);



        if (res.status !== 200) {

            toast.error(res.message, {

                position: "top-right",

                autoClose: 5000,

                hideProgressBar: false,

                closeOnClick: true,

                pauseOnHover: true,

                draggable: true,

                progress: undefined,

                toastId: res.status,

            });

        } else {

            toast.success("Voto computado com sucesso", {

                position: "top-right",

                autoClose: 5000,

                hideProgressBar: false,

                closeOnClick: true,

                pauseOnHover: true,

                draggable: true,

                progress: undefined,

                toastId: res.status,

            });

        }

    }



    function mapPhotos(photos) {

        return photos.map((photo) => {

            return (

                <div className="photos-tog">

                    <img className="top" src={`${url}${photo}`} alt="" />

                    {/* <img className="bottom" src={`${url}/${photo}`} /> */}

                </div>

            );

        });

    }



    function mapComments(comms) {

        return comms.map((comm) => {

            // console.log(comm);

            return (

                <div className="comment">

                    <div style={{width: "90%"}}>

                        <img

                            style={{ width: "30px" }}

                            src={`${url}${comm.authorData.picture}`}

                            alt=""

                        />

                        <div className="texts">

                            <p className="name">@{comm.authorData.username}</p>

                            <p className="comm" style={{lineBreak: "anywhere"}}>{comm.comment.comment}</p>

                        </div>

                    </div>

                    {(id === comm.comment.authorID || id === "72dc2f0c") && (

                        <BsTrashFill

                            onClick={() => {

                                const conf =

                                    window.confirm("Você tem certeza?");

                                if (conf) {

                                    deleteComm(comm.comment.id);

                                }

                            }}

                        ></BsTrashFill>

                    )}

                </div>

            );

        });

    }



    return (

        <>

            <div className="desktop-pub-container">

                <div>

                    <div className="left">

                        <Slider arrows={true} infinite={false} dots={false}>

                            {mapPhotos(data.pictures)}

                        </Slider>

                    </div>

                    <div className="right">

                        <div className="row">

                            <div className="author">

                                <Link

                                    to={`/profile/${data.authorData.refID}`}

                                    className="username"

                                >

                                    <img

                                        src={`${url}${data.authorData.picture}`}

                                        alt="Foto do Autor"

                                    />

                                </Link>

                                <div>

                                    <Link

                                        to={`/profile/${data.authorData.refID}`}

                                        className="username"

                                    >

                                        <p className="nome">

                                            @{data.authorData.name}

                                        </p>

                                        <p className="username">

                                            @{data.authorData.username}

                                        </p>

                                    </Link>

                                </div>

                            </div>

                            <div className="delete">

                                <BsX

                                    onClick={() => {

                                        onClose();

                                    }}

                                ></BsX>

                            </div>

                        </div>

                        <div className="post">

                            <h1>{data.data.place}</h1>

                            <p>{data.data.description}</p>

                        </div>

                        <div className="pontos">

                            <div className="esq">

                                <BsStar></BsStar>

                                <p>

                                    {data.data.score === ""

                                        ? "0"

                                        : data.data.score}{" "}

                                    Pontos

                                </p>

                            </div>



                            <div className="dir">

                                {(id === data.authorData.refID ||

                                    id === "72dc2f0c") && (

                                    <div className="deletar">

                                        Deletar

                                        <BsTrashFill

                                            onClick={() => {

                                                deletepost(id_post);

                                                mutateGlobal(["/posts", id]);

                                                onClose();

                                            }}

                                        ></BsTrashFill>

                                    </div>

                                )}



                                <p>Votar</p>

                                <Rating

                                    onChange={(value) => {

                                        giveLike(id_post, value);

                                    }}

                                    emptySymbol={<BsStar></BsStar>}

                                    fullSymbol={<BsStarFill></BsStarFill>}

                                ></Rating>

                            </div>

                        </div>

                        <div className="comments">

                            <form onSubmit={handleSubmit(useSubmit)}>

                                <input

                                    className={errors.login ? "error" : ""}

                                    type="text"

                                    name="query"

                                    ref={register({ required: true })}

                                    placeholder="Digite seu comentário."

                                />

                                <button type="submit">

                                    <BsArrowRightShort></BsArrowRightShort>

                                </button>

                            </form>

                            {mapComments(dataComm)}

                            {dataComm.length === 0 && (

                                <p style={{ textAlign: "center" }}>

                                    Sem comentários

                                </p>

                            )}

                        </div>

                    </div>

                </div>

            </div>

            <div

                onClick={() => {

                    onClose();

                }}

                className="black-ovl"

            ></div>

        </>

    );

}

