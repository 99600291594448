import React, { useState } from "react";

import Cookies from "universal-cookie";

import useSWR from "swr";

import ReactLoading from "react-loading";



import "./style.css";



import Pub from '../pub'



import { url } from "../../../utils/vars";



import get from "../../../hooks/get.fetch";



export default function Notify() {

    const cookies = new Cookies();

    const id = cookies.get("id");

    const hash = cookies.get("hash");

    

    const [openPub, changeOpenPub] = useState(false);

    const [idPub, changeIdPub] = useState(0);



    const { data, error } = useSWR(["/notify", id], (url, id) =>

        get(url, { id, hash }, {})

    );



    if (error) {

        <div className="desktop-notify-container">

            <h1>Notificações</h1>

            <p>Ocorreu um erro. Tente novamente mais tarde.</p>

        </div>;

    }



    if (!data) {

        return (

            <div className="desktop-notify-container">

                <h1>Notificações</h1>

                <ReactLoading

                    className="load"

                    type="bubbles"

                    color="#e82f3e"

                    width={150}

                />

            </div>

        );

    }



    if (data.notifications.length === 0) {

        return (

            <div className="desktop-notify-container">

                <h1>Notificações</h1>

                <p>Sem notificações no momento.</p>

            </div>

        );

    }



    console.log(data);



    function mapNots(nots) {

        return nots.map((not) => {

            return (

                <div onClick={() => {changeIdPub(not.pubID);changeOpenPub(true);}} className="not">

                    <img src={`${url}${not.authorPic}`} alt=""></img>

                    <div className="right">

                        <h2>{not.author}</h2>

                        <p>

                            {not.action === "commented"

                                ? "Comentou na sua publicação."

                                : "Votou na sua publicação"}

                        </p>

                        <p style={{fontSize: "10px", textDecoration: "underline", marginTop: "4px"}}>Clique para ver</p>

                    </div>

                </div>

            );

        });

    }



    return (

        <>

        {openPub && <Pub id={idPub} onClose={() => {changeOpenPub(false)}} />}

        <div className="desktop-notify-container">

            <h1>Notificações</h1>

            {mapNots(data.notifications)}

        </div>

        </>

    );

}

