import React from "react";

import { useStoreState } from "easy-peasy";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import NoFile from "../newFile_nofile";

import WithFile from "../newFile_file";

export default function Lista() {
    const images = useStoreState((state) => state.newFile.previewImages);

    function mapPrevImg(img) {
        return img.map((res, i) => {
            return <WithFile imagem={res} chave={i} key={i} />;
        });
    }

    return (
        <Slider
            style={{ background: "black" }}
            arrows={true}
            infinite={false}
            dots={false}
        >
            {mapPrevImg(images)}

            <NoFile></NoFile>
        </Slider>
    );
}
