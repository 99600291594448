import React from "react";
import useVH from "react-viewport-height";
import { useMediaQuery } from "react-responsive";
import { ToastContainer } from "react-toastify";
import { SWRConfig } from "swr";

import "./index.css";

import Routes from "./routes/desktop";
import RoutesMobile from "./routes/mobile";

const Mobile = ({ children }) => {
    const isTablet = useMediaQuery({ maxWidth: 950 });
    return isTablet ? children : null;
};

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 951 });
    return isDesktop ? children : null;
};

const options = {
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    shouldRetryOnError: false,
    refreshInterval: 0,
};

function App() {
    useVH();
    return (
        <SWRConfig value={options}>
            <div className="App">
                <ToastContainer limit={1}></ToastContainer>
                <Mobile>
                    <RoutesMobile></RoutesMobile>
                </Mobile>
                <Desktop>
                    <Routes></Routes>
                </Desktop>
            </div>
        </SWRConfig>
    );
}

export default App;
