import React from "react";

import { useForm } from "react-hook-form";

import { toast } from "react-toastify";

import Header from "../../components/header";

import MenuInf from "../../components/menu_inferior";
import { Link, useHistory } from "react-router-dom";
import deleteUsers from "../../../hooks/deleteUser";

export default function DropAcc() {
    //FORM VALIDATION

    // INIT USE FORM HOOK

    const { register, errors, handleSubmit } = useForm();

    const history = useHistory();

    //HANDLE SUBMIT

    const useSubmit = async (data) => {
        if (data.login.toLowerCase() === "confirmar") {
            const res = await deleteUsers();

            if (res.status === 200) {
                toast.success(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: res.status,
                });
                history.push(`/`);
            } else {
                toast.error(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: res.status,
                });
            }
        }
    };

    //PUT API VALUE ON INPUTS

    return (
        <>
            <Header></Header>

            <MenuInf></MenuInf>

            <div className="mobile-landingPage-container">
                <form
                    style={{ minWidth: "350px" }}
                    onSubmit={handleSubmit(useSubmit)}
                >
                    <h2>Apagar conta</h2>

                    <h5>
                        Para apagar sua conta por favor digite "confirmar" no
                        campo abaixo
                    </h5>
                    <input
                        className={errors.login ? "error" : ""}
                        type="text"
                        name="login"
                        ref={register({ required: true })}
                    />

                    {errors.desc ? (
                        <p className="error">
                            Preencha todos os campos corretamente.<br></br>
                        </p>
                    ) : null}

                    <button type="submit">Apagar</button>

                    <Link to="/edit">Deseja voltar a edição de perfil?</Link>
                </form>
            </div>
        </>
    );
}
