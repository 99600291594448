import React from "react";
import { BsX, BsArrowRightShort } from "react-icons/bs";
import useSWR from "swr";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Lista from "./sub/lista";

import "./style.css";

import get from "../../../hooks/get.fetch";
import getCookies from "../../../hooks/cookie";
import comment from "../../../hooks/comment";

export default function Comentarios({ pubID, onCl = () => {} }) {
    const { id, hash } = getCookies();
    const { register, errors, handleSubmit, setValue } = useForm();

    const { data, error, mutate } = useSWR(["/comments", pubID], (url, pubID) =>
        get(url, { id, hash }, { id: pubID })
    );

    if (error) {
        <h1 style={{ textAlign: "center" }}>
            Ocorreu um erro. Tente novamente mais tarde.
        </h1>;
    }

    if (!data) {
        return <h1 style={{ textAlign: "center" }}>Carregando...</h1>;
    }

    const useSubmit = async (data) => {
        const res = await comment(pubID, data.query);

        if (res.status !== 200) {
            toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: res.status,
            });
        }
        mutate();
        setValue("query", "");
    };

    return (
        <>
            <div className="comentarios-container">
                <div className="head">
                    <BsX className="none"></BsX>
                    <p>{data.length} comentários</p>
                    <BsX className="x" onClick={() => onCl()}></BsX>
                </div>
                <div className="lista">
                    <Lista comment={data}></Lista>
                </div>
                <div className="inputs">
                    <div className="right">
                        <form onSubmit={handleSubmit(useSubmit)}>
                            <input
                                className={errors.login ? "error" : ""}
                                type="text"
                                name="query"
                                ref={register({ required: true })}
                                placeholder="Digite seu comentário..."
                            />
                            <button type="submit">
                                <BsArrowRightShort></BsArrowRightShort>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="opacidade" onClick={() => onCl()}></div>
        </>
    );
}
