import { action, thunk } from "easy-peasy";
import api from "../../utils/api";
import Cookies from "universal-cookie";
import changeHash from "../../hooks/changeHash";

const cookies = new Cookies();

const newFile = {
    previewImages: [],
    selectedImages: [],

    actionLoadFeed: action((state, payload) => {
        let images = [...state.previewImages];
        let selectImages = [...state.selectedImages];

        for (let i = 0; i < payload.target.files.length; i++) {
            images.push(URL.createObjectURL(payload.target.files[i]));
            selectImages.push(payload.target.files[i]);
        }

        return {
            selectedImages: selectImages,
            previewImages: images,
        };
    }),

    actionApagar: action((state, payload) => {
        return {
            selectedImages: [],
            previewImages: [],
        };
    }),

    actionApagarUma: action((state, payload) => {
        const newSelecImages = state.selectedImages;
        const newprevImages = state.previewImages;

        newSelecImages.splice(payload, 1);
        newprevImages.splice(payload, 1);

        return {
            selectedImages: [...newSelecImages],
            previewImages: [...newprevImages],
        };
    }),

    thunkSend: thunk(async (actions, payload, { getStoreState }) => {
        return api
            .post(
                "/post",
                {
                    place: `${payload.pais} | ${payload.estado} | ${payload.ponto}`,
                    description: payload.desc,
                    recommendation: "",
                },
                {
                    headers: {
                        id: cookies.get("id"),
                        hash: cookies.get("hash"),
                    },
                }
            )
            .then((res) => {
                actions.thunkPhotos(res.data);
            });
    }),

    thunkPhotos: thunk(async (actions, payload, { getStoreState }) => {
        const state = getStoreState((state) => state);

        let formData = new FormData();

        state.newFile.selectedImages.forEach((e, i) => {
            formData.append(`img`, state.newFile.selectedImages[i]);
        });

        return api
            .post("/post/uploads", formData, {
                headers: {
                    pub: payload.pubID,
                    id: payload.id,
                    hash: payload.hash,
                },
            })
            .then((res) => {
                changeHash(res.data.id, res.data.hash);
                console.log(res);
            });
    }),
};

export default newFile;
