import React, { useState } from "react";

import { Redirect, useHistory } from "react-router-dom";

import useSWR from "swr";



import "./style.css";



//IMPORT GET COOKIES FUNCTION

import getCookies from "../../../hooks/cookie";



//IMPORT API URL



//IMPORT FEED SWR HOOK

import get from "../../../hooks/get.fetch";



import LoadingOvl from "../../components/loading_ovl";

import ErrorOvl from "../../components/error_ovl";

import Header from "../../components/header";

import Pub from "../../components/pub";

import Hero from "../../components/hero";

import ListaPosts from "../../components/lista_posts2";

import Popup from '../../components/popup'





export default function Feed() {

    const { id, hash } = getCookies();

    const history = useHistory();



    const [aberto, setAberto] = useState(false);

    const [pubId] = useState(false);



    //SE O USUÁRIO NÃO ESTIVER LOGADO

    if (!id || !hash) {

        history.push("/feed/error");

    }



    const { data, error } = useSWR(["/feed", id], (url, id) =>

        get(url, { id, hash }, { id })

    );



    if (error) {

        return (

            <>

                <ErrorOvl error={999}></ErrorOvl>

                <div className="bg_full"></div>

            </>

        );

    }



    if (!data) {

        return (

            <>

                <Header></Header>

                <LoadingOvl></LoadingOvl>

                <div className="bg_full"></div>

            </>

        );

    }



    if (data.posts.length === 0) {

        return (

            <>

                <Popup></Popup>

                <Header></Header>

                <Redirect to="/top"></Redirect>

                <div className="bg_full"></div>

            </>

        );

    }



    //SE O USUÁRIO ESTIVER LOGADO

    return (

        <>

            <Popup></Popup>

            {aberto && (

                <Pub

                    id={pubId}

                    onClose={() => {

                        setAberto(false);

                    }}

                ></Pub>

            )}

            <Header></Header>

            <Hero type="feed"></Hero>

            <div style={{ marginLeft: "15px" }}>

                <ListaPosts dados={data.posts} type="normal"></ListaPosts>

            </div>

        </>

    );

}

