import changeHash from "./changeHash";

import post from "./post.fetch";

import CryptoJS from "crypto-js";

export async function login(login, password) {
    const password_hash = CryptoJS.SHA3(password, {
        outputLength: 512,
    }).toString();

    //POST REQUEST TO /AUTH

    const { data, status } = await post(
        "/auth",

        {},

        { login, password: password_hash }
    );

    //WORK ON RESPONSES

    switch (status) {
        case 200:
            //QUANDO ELE LOGAR COM SUCESSO

            changeHash(data.id, data.hash);

            return {
                status: 200,

                message: "Login feito com sucesso.",
            };

        case 403:
            //QUANDO A SENHA ESTIVER ERRADA

            return {
                status: 403,

                message: "Senha incorreta.",
            };

        case 404:
            //QUANDO NÃO EXISTIR USUÁRIO COM ESSE LOGIN

            return {
                status: 404,

                message: "Usuário não encontrado.",
            };

        default:
            //ERRROS NÃO CONTROLADOS

            return {
                status: 999,

                message: "Ocorreu um erro. Tente novamente mais tarde.",
            };
    }

    // RETURN STRUC

    /* { 

        STATUS: NUMBER (HTTPS STATUS CODES),

        MESSAGE: STRING (MESSAGE FOR USER)

    } */
}
