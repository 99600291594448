import React from "react";
import { Link } from "react-router-dom";
import {
    BsHouse,
    BsSearch,
    BsBell,
    BsPerson,
    BsPlusSquare,
} from "react-icons/bs";
import Cookies from "universal-cookie";

import "./style.css";

export default function MenuInferior({ bg }) {
    let classes;

    if (bg === "none") {
        classes = "menu-inferior-container no";
    } else {
        classes = "menu-inferior-container";
    }

    const cookies = new Cookies();
    const id = cookies.get("id");

    return (
        <div className={classes}>
            <Link to="/feed">
                <BsHouse className="ico-pqn"></BsHouse>
            </Link>
            <Link to="/search?query=">
                <BsSearch className="ico-pqn"></BsSearch>
            </Link>
            <Link to="/new">
                <BsPlusSquare className="ico-grn"></BsPlusSquare>
            </Link>
            <Link to="/notifications">
                <BsBell className="ico-pqn"></BsBell>
            </Link>
            <Link to={`/profile/${id}`}>
                <BsPerson className="ico-pqn"></BsPerson>
            </Link>
        </div>
    );
}
