import React, { useState } from "react";
import Slider from "react-slick";
import { BsStar, BsChatDotsFill, BsX, BsTrashFill } from "react-icons/bs";
import Fullpage, {
    FullPageSections,
    FullpageSection,
} from "@ap.cx/react-fullpage";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.css";

import { url } from "../../../utils/vars";
import deletePost from "../../../hooks/deletePost";
import getCookies from "../../../hooks/cookie";

import MenuInf from "../menu_inferior";
import Comentarios from "../comentarios";
import Like from "../like";

export default function ListaPosts({ dados }) {
    const history = useHistory();
    const [commAberto, setCommAberto] = useState(false);
    const [likeAberto, setLikeAberto] = useState(false);
    const [postId, setPostId] = useState(0);
    const [acceptView, setAcceptView] = useState(false);

    const { id } = getCookies();

    function Warning() {
        async function deletepost2() {
            const res = await deletePost(postId);
            if (res.status === 200) {
                toast.success(res.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: res.status,
                });
            } else {
                toast.error(res.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: res.status,
                });
            }
        }
        return (
            <>
                <div className="warning-container">
                    <p>
                        Você tem certeza que quer apagar essa publicação?
                        <br />
                        <b>(NÃO PODE SER REVERTIDO)</b>
                    </p>
                    <div>
                        <button
                            onClick={() => {
                                deletepost2();
                                setAcceptView(false);
                                history.push("/feed");
                            }}
                            className="sim"
                        >
                            Apagar
                        </button>
                        <button
                            onClick={() => {
                                setAcceptView(false);
                            }}
                            className="nao"
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
                <div className="black-ovl"></div>
            </>
        );
    }

    function mapPosts(dados) {
        return dados.map((post) => {
            return (
                <FullpageSection>
                    <PostCard post={post}></PostCard>
                </FullpageSection>
            );
        });
    }

    function PostCard({ post }) {
        const [descAberto, setDescAberto] = useState(false);
        const [pageAtual, setPageAtual] = useState(1);

        function DescPeq() {
            if (post.data.description.length > 100) {
                return (
                    <p
                        onClick={() => {
                            setDescAberto(true);
                        }}
                    >
                        {post.data.description.substring(0, 100)}...
                        <b>Ver mais</b>
                    </p>
                );
            } else {
                return (
                    <p
                        onClick={() => {
                            setDescAberto(true);
                        }}
                    >
                        {post.data.description}
                    </p>
                );
            }
        }

        function DescGrn() {
            return (
                <p
                    onClick={() => {
                        setDescAberto(false);
                    }}
                    className="big"
                >
                    {post.data.description}
                </p>
            );
        }

        function mapPhotos(photos) {
            return photos.map((photo) => {
                return (
                    <div className="photo">
                        <div className="ovl">
                            <div className="ovl-top"></div>
                            <div className="ovl-bot"></div>
                        </div>
                        <img src={`${url}${photo}`} className="top" alt="" />
                        {/* <img src={`${url}/${photo}`} className="bot" /> */}
                    </div>
                );
            });
        }

        return (
            <>
                <div className="mobile-postonlist-container">
                    <div
                        onClick={() => {
                            history.goBack();
                        }}
                        className="close"
                    >
                        <BsX></BsX>
                    </div>
                    {id === post.authorData.refID && (
                        <div
                            onClick={() => {
                                setPostId(post.data.pub);
                                setAcceptView(true);
                            }}
                            className="apagar"
                        >
                            <p>Apagar</p>
                            <BsTrashFill></BsTrashFill>
                        </div>
                    )}
                    <div className="photos">
                        <Slider
                            afterChange={(current) => {
                                setPageAtual(current + 1);
                            }}
                            style={{ height: "calc(var(--vh, 1vh) * 100)" }}
                            arrows={false}
                            infinite={false}
                            dots={false}
                        >
                            {mapPhotos(post.pictures)}
                        </Slider>
                    </div>
                    {post.pictures.length > 1 && (
                        <div className="counter">
                            <p>
                                {("0" + pageAtual).slice(-2)}/
                                {("0" + post.pictures.length).slice(-2)}
                            </p>
                        </div>
                    )}
                    <div className="resume">
                        <div className="left">
                            <div className="first">
                                <Link to={`/profile/${post.authorData.refID}`}>
                                    @{post.authorData.username}
                                </Link>
                                <div className="pontos">
                                    <BsStar></BsStar>
                                    <p>
                                        {post.data.score === ""
                                            ? 0
                                            : post.data.score}{" "}
                                        Pontos
                                    </p>
                                </div>
                            </div>
                            <h1>{post.data.place}</h1>
                            {descAberto ? (
                                <DescGrn></DescGrn>
                            ) : (
                                <DescPeq></DescPeq>
                            )}
                        </div>
                        <div className="right">
                            <BsChatDotsFill
                                onClick={() => {
                                    setCommAberto(true);
                                    setPostId(post.data.pub);
                                }}
                            ></BsChatDotsFill>
                            <BsStar
                                onClick={() => {
                                    setLikeAberto(true);
                                    setPostId(post.data.pub);
                                }}
                            ></BsStar>
                            <img
                                onClick={() => {
                                    history.push(
                                        `/profile/${post.authorData.refID}`
                                    );
                                }}
                                src={`${url}${post.authorData.picture}`}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="mobile-listaposts-container">
            {commAberto && (
                <Comentarios
                    pubID={postId}
                    onCl={() => {
                        setCommAberto(false);
                    }}
                ></Comentarios>
            )}

            {likeAberto && (
                <Like
                    id_post={postId}
                    onCl={() => {
                        setLikeAberto(false);
                    }}
                ></Like>
            )}
            <MenuInf bg="none"></MenuInf>
            {acceptView && <Warning></Warning>}

            <Fullpage>
                <FullPageSections>
                    {mapPosts(dados)}
                    <FullpageSection>
                        <div className="no-more">
                            <h1>Não temos mais posts para te mostrar.</h1>
                            <p>Siga mais pessoas para ver mais posts.</p>
                        </div>
                    </FullpageSection>
                </FullPageSections>
            </Fullpage>
        </div>
    );
}
