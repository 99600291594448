import React from "react";



import { Link, useHistory } from "react-router-dom";



import Cookies from "universal-cookie";



import useSWR from "swr";



import MenuInf from "../../components/menu_inferior";



import Header from "../../components/header";



import LoadingOvl from "../../components/loading_ovl";



import { url } from "../../../utils/vars";



import get from "../../../hooks/get.fetch";



import "./style.css";



export default function Notificacoes() {

    const history = useHistory()

    const cookies = new Cookies();



    const id = cookies.get("id");



    const hash = cookies.get("hash");



    const { data, error } = useSWR(["/notify", id], (url, id) =>

        get(url, { id, hash }, {})

    );



    if (error) {

        <div className="desktop-notify-container">

            <h1>Notificações</h1>



            <p>Ocorreu um erro. Tente novamente mais tarde.</p>

        </div>;

    }



    if (!data) {

        return (

            <>

                <Header></Header>



                <LoadingOvl></LoadingOvl>



                <div className="bg_full"></div>



                <MenuInf bg="full"></MenuInf>

            </>

        );

    }

    

    if(data.notifications.length === 0) {

        return (

        <>

            <Header></Header>



            <div className="notificacoes-container">

                <h3 style={{textAlign: "center", width: "100%"}}>Sem notificações no momento.</h3>

            </div>



            <MenuInf bg="full"></MenuInf>

        </>

        )

    }



    return (

        <>

            <Header></Header>



            <div className="notificacoes-container">{mappingDias()}</div>



            <MenuInf bg="full"></MenuInf>

        </>

    );



    function mappingDias() {

        const arr = [];



        data.notifications.forEach((data) => {

            const date = new Date(data.date);



            let dataFormatada =

                date.getFullYear() +

                "-" +

                (date.getMonth() + 1) +

                "-" +

                date.getDate();



            if (!arr.includes(dataFormatada)) {

                arr.push(dataFormatada);

            }



            return 0;

        });



        const res = arr.map((dia) => {

            const arrayNovo = data.notifications.filter((data) => {

                const date = new Date(data.date);



                let dataFormatada =

                    date.getFullYear() +

                    "-" +

                    (date.getMonth() + 1) +

                    "-" +

                    date.getDate();



                return dataFormatada === dia;

            });



            const date = new Date(dia);



            let dataFormatada =

                ("0" + date.getDate()).slice(-2) +

                "/" +

                ("0" + (date.getMonth() + 1)).slice(-2) +

                "/" +

                date.getFullYear();



            return (

                <div className="dia">

                    <h2>{dataFormatada}</h2>



                    {mappingNotif(arrayNovo)}

                </div>

            );

        });



        return res;

    }



    function mappingNotif(notifications) {

        return notifications.map((notif, i) => {

            let text;



            if (notif.action === "rated") {

                text = "Votou em sua publicação.";

            } else {

                text = "Comentou em sua publicação.";

            }



            return (

                <div onClick={() => {history.push(`post/${notif.pubID}`)}} key={i} className="noti">

                    <div className="photo">

                        <img alt="profile" src={`${url}${notif.authorPic}`} />

                    </div>



                    <Link className="resume">

                        <h3>@{notif.author}</h3>



                        <p>{text}</p>

                        

                         <p style={{fontSize: "10px", textDecoration: "underline", marginTop: "4px"}}>Clique para ver</p>

                    </Link>

                </div>

            );

        });

    }

}

