import React from "react";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import useSWR from "swr";
import { toast } from "react-toastify";

import get from "../../../hooks/get.fetch";
import follow from "../../../hooks/follow.swr";

import "./style.css";

import Header from "../../components/header";
import ListaPosts from "../../components/lista_posts2";
import ProfileDesc from "../../components/profile_desc";
import invertArr from "../../../hooks/invertArray";

export default function Profile() {
    const cookies = new Cookies();

    const { id } = useParams();
    const idLogado = cookies.get("id");
    const hash = cookies.get("hash");

    const { data, error, mutate } = useSWR(["/users", id], (url, id) =>
        get(url, { id: idLogado, hash }, { id })
    );

    if (error) {
        return <h1>{error.response.status}</h1>;
    }

    if (!data) {
        return <h1>Loading</h1>;
    }

    function List({ id }) {
        let { data, error } = useSWR(["/posts", id], (url, id) =>
            get(url, {}, { id_search: id })
        );

        if (error) {
            <h1>Error</h1>;
        }

        if (!data) {
            return <h1>Loading</h1>;
        }

        if (data.length === 0) {
            return <h1>Sem posts</h1>;
        }

        return <ListaPosts dados={invertArr(data)}></ListaPosts>;
    }

    function handleFollow() {
        follow(id).then((res) => {
            if (!res.status === 200) {
                toast.error(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    toastId: res.status,
                });
                mutate();
            }
        });
    }

    return (
        <>
            <Header></Header>
            <div className="desktop-profile-container">
                <ProfileDesc
                    data={data}
                    id={id}
                    idLogado={idLogado}
                    handleFollow={handleFollow}
                ></ProfileDesc>

                <div style={{ width: "100%", marginTop: "15px" }}>
                    <List id={id}></List>
                </div>
            </div>
        </>
    );
}
