import React, { useState, useEffect } from 'react';



import { BsX } from 'react-icons/bs';



import Cookies from 'universal-cookie';



import Slider from "react-slick";







import './style.css'







import foto1 from '../../../assets/mobile-etapa1.jpg'



import foto2 from '../../../assets/mobile-etapa2.jpg'



import foto3 from '../../../assets/mobile-etapa3.jpg'





import fotoi1 from '../../../assets/ip_1.jpg'



import fotoi2 from '../../../assets/ip_2.jpg'



import fotoi3 from '../../../assets/ip_3.jpg'







export default function PopUp() {



    const cookies = new Cookies();







    const accept = cookies.get('pop_acc');


    const [isOpen, setIsOpen] = useState(false);



    const [isNewOpen, setIsNewOpen] = useState(false);



    const [type, setType] = useState(0);









    function handleChangeAcc() {



        cookies.set('pop_acc', true, {



            path: "/",



            expires: new Date(new Date().setDate(new Date().getDate() + 7)),



        })



    }







    useEffect(() => {



        if(!accept === true || !accept === "true") {



            setIsOpen(true);



        }



    }, [accept])







    if(!isOpen) {



        return (<></>)



    }







    if(isNewOpen) {



        return (



            <>



                <div className="mobile-popup">



                    <div className="close">



                    

                        <div className="buttons">

                            <button onClick={() => setType(0)}>Android</button>

                            <button onClick={() => setType(1)}>Iphone</button>

                        </div>



                        <BsX onClick={() => {setIsOpen(false); setIsNewOpen(false); handleChangeAcc()}}></BsX>



                    </div>



                    <div className="content">



                    {type === 0 ? (<Slider>



                                    <img src={foto1} alt="Etapa 1" />



                                    <img src={foto2} alt="Etapa 2" />



                                    <img src={foto3} alt="Etapa 3" />



                                    </Slider>) : (

                                    <Slider>



                                    <img src={fotoi1} alt="Etapa 1" />



                                    <img src={fotoi2} alt="Etapa 2" />



                                    <img src={fotoi3} alt="Etapa 3" />



                                </Slider>

                                )}



                    </div>



                </div>



                <div className="opacidade" onClick={() => {setIsOpen(false); setIsNewOpen(false); handleChangeAcc()}}></div>



            </>



        )



    }







    return (



        <div className="mobile-popup-pre-accept">



            <div className="close">



                <BsX onClick={() => {setIsOpen(false)}}></BsX>



            </div>



            <div className="content">



                <p>Deseja adicionar o My Best Trip à sua tela inicial?</p>



            </div>



            <div className="buttons">



                <button onClick={() => {setIsOpen(false); handleChangeAcc()}} className="grey-button">Fechar</button>



                <button onClick={() => {setIsNewOpen(true)}} className="red-button">Adicionar</button>



            </div>



        </div>



    )



}



